import React, {useEffect} from "react";
import { Grid, Paper, Typography, Avatar, Box } from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import { indigo, pink, amber } from "@mui/material/colors";
import { useSelector } from "react-redux";
import useMemberCall from "../../hooks/useMemberCall";
import useContactFormCall from "../../hooks/useContactFormCall";
import usePostCall from "../../hooks/usePostCall";

const KpiCards = () => {

    const {getMembers} = useMemberCall();
    const {getMessages} = useContactFormCall();
    const {getPosts} = usePostCall();
  const {members} = useSelector((state) => state.member);
  const {contactFormData} = useSelector((state) => state.form);
  const {posts} = useSelector((state) => state.post);


  useEffect(() => {
    getMembers();
    getMessages()
    getPosts()
  }, [])
  
  

  const data = [
    {
      title: "Active Members",
      metric: members[0]?.active_member_count,
      icon: <GroupsIcon sx={{ fontSize: "3rem" }} />,
      color: indigo[900],
      bgColor: indigo[100],
    },
    {
      title: "Unread Messages",
      metric: contactFormData[0]?.unread_messages,
      icon: <MarkunreadMailboxIcon sx={{ fontSize: "3rem" }} />,
      color: pink[900],
      bgColor: pink[100],
    },
    {
      title: "Active Posts",
      metric: posts?.filter(i => i.is_published === true).length,
      icon: <RssFeedIcon sx={{ fontSize: "3rem" }} />,
      color: amber[900],
      bgColor: amber[100],
    },
  ];

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      {data.map((item) => (
        <Grid
          item
          key={item.title}
          xs={12}
          sm={10}
          md={6}
          lg={4}
          sx={{ minWidth: "250px" }}
        >
          <Paper sx={{ p: 2 }} elevation={10}>
            <Box sx={{ display: "flex" }}>
              <Avatar
                sx={{
                  width: "4rem",
                  height: "4rem",
                  color: item.color,
                  backgroundColor: item.bgColor,
                  my: "auto",
                  mx: 2,
                }}
              >
                {item.icon}
              </Avatar>
              <Box sx={{ mx: 3 }}>
                <Typography variant="button">{item.title}</Typography>
                <Typography variant="h4">{item.metric}</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default KpiCards;
