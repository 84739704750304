import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    featureData: [],
    loading:false,
    error:false,
}

const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, {payload}) => {
        state.featureData = payload;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
  }
});

export const {fetchStart, fetchFail, loadData} = featureSlice.actions

export default featureSlice.reducer