import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";import MessageIcon from '@mui/icons-material/Message';
import BookIcon from '@mui/icons-material/Book';
import EventIcon from '@mui/icons-material/Event';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { useNavigate } from "react-router-dom";

const icons = [
  {
    icon: <DashboardIcon />,
    title: "Dashboard",
    url: "/admin/",
  },
  {
    title: "Members",
    icon: <GroupsIcon />,
    url: "/admin/members/",
  },
  {
    title: "Messages",
    icon: <MessageIcon />,
    url: "/admin/messages/",
  },
  {
    title: "Posts",
    icon: <BookIcon />,
    url: "/admin/posts/",
  },
  {
    title: "Events",
    icon: <EventIcon />,
    url: "/admin/events/",
  },
  {
    title: "Participants",
    icon: <Diversity2Icon />,
    url: "/admin/participants/",
  },
  {
    title: "About",
    icon: <InfoIcon />,
    url: "/admin/about/",
  },
  {
    title: "Boards",
    icon: <LocalPoliceIcon />,
    url: "/admin/boards/",
  },
  {
    title: "Partners",
    icon: <HandshakeIcon />,
    url: "/admin/partners/",
  },
  {
    title: "Footer",
    icon: <BrandingWatermarkIcon />,
    url: "/admin/footer/",
  },
  {
    title: "Contact",
    icon: <ContactPageIcon />,
    url: "/admin/contact/",
  },
  {
    title: "Subscribers",
    icon: <LoyaltyIcon />,
    url: "/admin/subscribers/",
  },
  {
    title: "Home Page",
    icon: <HomeIcon />,
    url: "/",
  },
];

const iconStyle = {
  color: "#eee",
  "& .MuiSvgIcon-root": { color: "#eee" },
  "&:hover": { color: "red" },
  "&:hover .MuiSvgIcon-root": { color: "red" },
};

const MenuListItems = () => {
  const navigate = useNavigate();
  return (
    <div>
      <List>
        {icons?.map((item, index) => (
          <ListItem key={index} disablePadding>
            {item.url.includes("http") && (
              <ListItemButton to={item.url} sx={iconStyle}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            )}

            {!item.url.includes("http") && (
              <ListItemButton onClick={() => navigate(item.url)} sx={iconStyle}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MenuListItems;
