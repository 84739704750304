import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const NoMatch = () => {
  return (
    <Container maxWidth="sm">
      <Box mt={5} textAlign="center">
        <Typography variant="h1" color="primary" gutterBottom>
          404 Not Found
        </Typography>
        <Typography variant="h5" color="textSecondary" paragraph>
          The page you are looking for does not exist.
        </Typography>
        <Link to="/">
          <Button variant="contained" color="primary">
            Go to Home
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default NoMatch;
