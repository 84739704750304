import {useDispatch} from 'react-redux';
import useAxios, {axiosPublic} from '../hooks/useAxios';
import { fetchStart, loadData, fetchFail } from '../features/partnerSlice';
import {toastSuccessNotify} from '../helper/ToastNotify';

const usePartnerCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();

    const getPartners = async()=>{
        dispatch(fetchStart())
        try {
            const {data} = await axiosPublic.get('api/partners/');
            dispatch(loadData(data))
        } catch (error) {
            dispatch(fetchFail())
            console.log(error)
        }
    }

    const addPartner = async(info) => {
      dispatch(fetchStart());
      try {
        const {data} = await axiosWithToken.post('api/partners/', info);
        toastSuccessNotify(`${data.name} created successfully`)
        getPartners();

      } catch (error) {
        console.log(error);
      }
    }
    const updatePartner = async(id, info) => {
      dispatch(fetchStart());
      try {
        const {data} = await axiosWithToken.put(`api/partners/${id}/`, info);
        toastSuccessNotify(`${data.name} updated successfully`)
        getPartners();

      } catch (error) {
        console.log(error);
      }
    }

    const deletePartner = async(id) => {
      dispatch(fetchStart());
      try {
        await axiosWithToken.delete(`api/partners/${id}/`);
        toastSuccessNotify(`partner with id:${id} deleted successfully`)
        getPartners();

      } catch (error) {
        console.log(error);
      }
    }

  return {
    getPartners,
    addPartner,
    updatePartner,
    deletePartner
  }
}

export default usePartnerCall