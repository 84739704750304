// Calendar.js
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import 'react-calendar/dist/Calendar.css';
import UpcomingEvents from './UpcomingEvents';

import Badge from '@mui/material/Badge';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

const h2Style = {
  "fontFamily":"Muli-SemiBold",
  "textTransform":"uppercase",
  "letterSpacing": "7px",
  "border": "1px solid #dad8e4",
  "display": "block",
  "padding": "0 10px",
  "marginBottom": "20px",
  "marginTop": "40px",
  "borderRadius": "6px"
}

const MyCalendar = ({events}) => {
  const [date, setDate] = useState(new Date());
  const [currEvents, setCurrEvents] = useState([]);


  const getEventsOnDate = (date) => {
    const selectedDateString = date.toISOString().split('T')[0];
    return events.filter((event) => event.dateJs?.toString().split('T')[0] === selectedDateString);
  };

  const tileContent = ({ date }) => {
    const eventCount = getEventsOnDate(date).length;
    return eventCount > 0 ? (
      <Badge badgeContent={eventCount} color="warning">
        <LocalActivityIcon color="red" />
      </Badge>
    ) : null;
  };

  const handleClickDay = (value, event) => {
    const selectedDateEvents = getEventsOnDate(value);
    setCurrEvents(selectedDateEvents);
  };

  return (
    <Grid container>
      <Grid item pt={5} md={4}>
      <Calendar
        onChange={setDate}
        value={date}
        tileContent={tileContent}
        onClickDay={handleClickDay}
      />
      </Grid>
      <Grid item md={8}>
        {currEvents?.length <= 0 ? <Typography style={h2Style} textAlign={'center'} variant="h4" pt={5}>Inga events hittade på valt datum.
</Typography> : <UpcomingEvents events={currEvents} title={<Typography style={h2Style} textAlign={'center'} variant="h4" mb={2}>{currEvents?.length > 1 ? currEvents?.length : ""} Evenemang för {date.toLocaleDateString()}</Typography>} />}
        
        {/* <Typography textAlign={'center'} variant="h4" mb={2}>{currEvents?.length > 1 ? currEvents?.length : ""} Evenemang för {date.toLocaleDateString()}</Typography>
        <ul>
          {currEvents?.map((event) => (
            <li key={event.id} style={{"marginBottom": "5px"}}>
              <EventCard event={event} />
            </li>
          ))}
        </ul> */}
      </Grid>

    </Grid>
  );
};

export default MyCalendar;
