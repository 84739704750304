import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Select,
  MenuItem,
  Checkbox,
  Paper
} from '@mui/material';
//import {MultiSelect, MultiSelectItem, Select, SelectItem} from '@tremor/react';
import BadgeDelta from '@mui/material/Badge';
import useSortColumn from '../../hooks/useSortColumn';
import UpgradeIcon from "@mui/icons-material/Upgrade";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { arrowStyle, btnHoverStyle, flex } from "../../styles/globalStyle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import useContactFormCall from "../../hooks/useContactFormCall";
import { useSelector } from 'react-redux';


const  MessagesTable = ({setOpen, setInfo}) => {
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedEmails, setSelectedEmails] = useState([]);
  const {contactFormData} = useSelector((state) => state.form);
  const { deleteMessage } = useContactFormCall();


  const columnObj = {
    name: 1,
    created_date: 1,
    status: 1,
    id: 1,
  };

  const { sortedData, handleSort, columns } = useSortColumn(
    contactFormData,
    columnObj
    );

  const isUserEmailSelected = (message) =>
    (`${message.status}` === selectedStatus || selectedStatus === 'all') &&
    (selectedEmails?.includes(message.email) || selectedEmails?.length === 0);


  // const uniqueEmails = new Set(Array.isArray(contactFormData) && contactFormData?.map((item) => item['email']));

  const uniqueEmails = new Set(Array.isArray(contactFormData) ? contactFormData.map((item) => item['email']) : []);



  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Messages Detail</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows messages detail"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <div className='flex space-x-2'>
        <Select
        className='w-50'
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="true">Read</MenuItem>
          <MenuItem value="false">Unread</MenuItem>
        </Select>
        <Select
          multiple
          placeholder='Select Email'
          className="w-50"
          value={selectedEmails}
          onChange={(e) => setSelectedEmails(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
        >

          {Array.isArray([...uniqueEmails]) && [...uniqueEmails]?.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedEmails?.includes(item)} />
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              <Box sx={arrowStyle} onClick={() => handleSort("id")}>
                <Typography variant="body" noWrap>
                  #
                </Typography>
                {columns.id === 1 && <UpgradeIcon />}
                {columns.id !== 1 && <VerticalAlignBottomIcon />}
              </Box>
              </TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("name")}>
                <Typography variant="body" noWrap>
                  Name
                </Typography>
                {columns.name === 1 && <UpgradeIcon />}
                {columns.name !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Message</TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("status")}>
                <Typography variant="body" noWrap>
                Status
                </Typography>
                {columns.status === 1 && <UpgradeIcon />}
                {columns.status !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedData) && sortedData?.filter((item) => isUserEmailSelected(item))
              .map((item) => (
                
                <TableRow key={item.id}>
                  <TableCell align='center'>{item.id}</TableCell>
                  <TableCell align='center'>{item.name}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.message}</TableCell>
                  
                  <TableCell align="center">
                    <BadgeDelta
                      badgeContent={`${item.status === true ? "Read" : "Unread"}`}
                      color={`${item.status === true ? "success" : "error"}`}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={flex}>
                    <IconButton
                        color='warning'
                        component='span'
                        aria-label="Info"
                        title={`Update message data for ${item.email}`}
                        >
                          <BorderColorIcon onClick={() => {
                        setOpen(true);
                        setInfo(item);
                        }} 
                        sx={btnHoverStyle} 
                        />
                        </IconButton>
                        <IconButton
                        color='danger'
                        component='span'
                        aria-label="Info"
                        title={`Delete message for ${item.name}`}
                        ><DeleteForeverIcon onClick={() => deleteMessage(item.id)} sx={btnHoverStyle} /></IconButton>
                    </Box>
                  
                </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default MessagesTable;