import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import usePostCall from '../hooks/usePostCall';
import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';

const PostDetail = () => {
  const { id } = useParams();
  const {getCurrentPost} = usePostCall();
  const {currentPost} = useSelector((state) => state.post);

  //console.log(id);
  

  useEffect(() => {
    getCurrentPost(id);
  }, [])

  //console.log(singlePost);

  // Fetch the post details including image and author based on the postId parameter
  // You can use Redux, context, or an API request to fetch data

  return (
    <>
    <Helmet>
      <title>{`${currentPost ? currentPost.title : 'Post Detail'}`}</title>
      <meta name='description' content={`${currentPost ? currentPost.content : 'This is post detail page'}`} />
    </Helmet>
    <Container maxWidth="md" className='mt-100'>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>
          {currentPost?.title.toLowerCase()}
        </Typography>
        <Typography variant="body1">
          <img src={currentPost?.image} alt={currentPost?.title} style={{ maxWidth: '100%' }} />
        </Typography>
        <Typography variant="body1">
          <strong>Author:</strong> {currentPost?.author}
        </Typography>
        <Typography variant="body1">
          {currentPost?.content}
        </Typography>
      </Paper>
    </Container>
    </>
  );
};

export default PostDetail;
