import {
    TextField,
    Box,
    Paper
  } from '@mui/material';
  import LoadingButton from "@mui/lab/LoadingButton";
  
  import { Form } from "formik";
  import { useSelector } from "react-redux";
  import * as Yup from "yup";


export const eventSchema = Yup.object().shape({
  name: Yup.string().required("Namn är obligatoriskt"),
    
  email: Yup.string()
    .email("vänligen ange en giltig e-postadress")
    .required("E-post är obligatoriskt."),
  event_name: Yup.string().required("Evenamang name är obligatoriskt"),
  message: Yup.string().required("Meddelande är obligatoriskt"),
  });
  
  const EventFormData = ({ values, handleChange, errors, touched, handleBlur }) => {
    const { loading } = useSelector((state) => state.evenemang);
  return (
    <Form>
      <Paper elevation={3} style={{ padding: '20px' }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TextField
        label="Namn"
        name="name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.name && errors.name}
        error={touched.name && Boolean(errors.name)}
        fullWidth
        required
      />
      <TextField
        label="E-Post"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email && errors.email}
        error={touched.email && Boolean(errors.email)}
        fullWidth
        required
      />
      <TextField
        label="Evenemang"
        name="event_name"
        value={values.event_name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.event_name && errors.event_name}
        error={touched.event_name && Boolean(errors.event_name)}
        fullWidth
        required
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
          label="Meddelande"
          name="message"
          multiline
          rows={4}
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.message && errors.message}
          error={touched.message && Boolean(errors.message)}
          fullWidth
          required
        />
        <LoadingButton
          loading={loading}
          loadingPosition="center"
          variant="contained"
          type="submit"
        >
          Skicka in
        </LoadingButton>
      </Box>
      </Paper>
    </Form>
  );
  };

  export default EventFormData;