import { useDispatch } from 'react-redux'
import { axiosPublic } from './useAxios'
import {fetchStart, loadData, fetchFail} from '../features/formSlice';

import useAxios from './useAxios';
import {toastSuccessNotify, toastErrorNotify} from '../helper/ToastNotify'

const useContactFormCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();


    const  sendContactForm = async (info) => {
        dispatch(fetchStart())
        try {
            const {data} = await axiosPublic.post("api/contact/", info)
            dispatch(loadData(data))
            toastSuccessNotify('Ditt meddelande har skickats.')
        } catch (error) {
            dispatch(fetchFail());
            toastErrorNotify(error.response.statusText)
            console.log(error);
        }
    }

    const getMessages = async () => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosWithToken.get('api/contact/');
            dispatch(loadData(data));
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
        }
    }

    const deleteMessage = async (id) => {
        try {
            await axiosWithToken.delete(`api/contact/${id}/`)
            toastSuccessNotify(`message with this ${id} successfuly deleted.`)
            getMessages();
        } catch (error) {
            console.log(error);
            toastErrorNotify(`message with this ${id} can not be deleted.`)
        }
    }

    const updateMessage = async (info) => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosWithToken.put(`api/contact/${info.id}/`, info)
            dispatch(loadData(data))
            getMessages();
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
        }
    }

  return {sendContactForm, getMessages, deleteMessage, updateMessage }
}

export default useContactFormCall