import {Outlet} from 'react-router-dom';
import NavMenu from '../components/NavMenu';
import Footer from '../components/Footer';

export default function PageLayout () {
    return (
        <>
        <NavMenu />
        <Outlet />
        <Footer/>
        </>
    )
}