import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Select,
  MenuItem,
  Checkbox,
  Paper
} from '@mui/material';
//import {MultiSelect, MultiSelectItem, Select, SelectItem} from '@tremor/react';
import BadgeDelta from '@mui/material/Badge';
import useSortColumn from '../../hooks/useSortColumn';
import UpgradeIcon from "@mui/icons-material/Upgrade";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { arrowStyle, btnHoverStyle, flex } from "../../styles/globalStyle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import usePostCall from "../../hooks/usePostCall";
import { useSelector } from 'react-redux';


const  PostsTable = ({setOpen, setInfo}) => {
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const {posts} = useSelector((state) => state.post);
  const { deletePost} = usePostCall();


  const columnObj = {
    post_date: 1,
    author: 1,
    is_published: 1,
    id: 1,
  };

  const { sortedData, handleSort, columns } = useSortColumn(
    posts,
    columnObj
    );

  const isPostAutorsSelected = (post) =>
    (`${post.is_published}` === selectedStatus || selectedStatus === 'all') &&
    (selectedAuthors?.includes(post.author) || selectedAuthors?.length === 0);

  
  const uniqueAuthors = new Set(Array.isArray(posts) ? posts.map((item) => item['author']) : []);

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Posts Info</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows posts information"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <div className='flex space-x-2'>
        <Select
        className='w-50'
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="true">Published</MenuItem>
          <MenuItem value="false">Draft</MenuItem>
        </Select>
        <Select
          multiple
          placeholder='Select Author'
          className="w-50"
          value={selectedAuthors}
          onChange={(e) => setSelectedAuthors(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
        >

          {Array.isArray([...uniqueAuthors]) && [...uniqueAuthors]?.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedAuthors?.includes(item)} />
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              <Box sx={arrowStyle} onClick={() => handleSort("id")}>
                <Typography variant="body" noWrap>
                  #
                </Typography>
                {columns.id === 1 && <UpgradeIcon />}
                {columns.id !== 1 && <VerticalAlignBottomIcon />}
              </Box>
              </TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("author")}>
                <Typography variant="body" noWrap>
                  Author
                </Typography>
                {columns.author === 1 && <UpgradeIcon />}
                {columns.author !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("created")}>
                <Typography variant="body" noWrap>
                  Date
                </Typography>
                {columns.post_date === 1 && <UpgradeIcon />}
                {columns.post_date !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("is_published")}>
                <Typography variant="body" noWrap>
                Status
                </Typography>
                {columns.is_published === 1 && <UpgradeIcon />}
                {columns.is_published !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center">Post Image</TableCell>
              
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedData) && sortedData?.filter((item) => isPostAutorsSelected(item))
              .map((item) => (
                
                <TableRow key={item.id}>
                  <TableCell align='center'>{item.id}</TableCell>
                  <TableCell align='center'>{item.title}</TableCell>
                  <TableCell align="center">{item.category}</TableCell>
                  <TableCell align="center">{item.author}</TableCell>
                  <TableCell align="center">{item.post_date.split('T')[0]}</TableCell>
                  
                  <TableCell align="center">
                    <BadgeDelta
                      badgeContent={`${item.is_published === true ? "Published" : "Draft"}`}
                      color={`${item.is_published === true ? "success" : "error"}`}
                    />
                  </TableCell>
                  <TableCell align="center">
                  <img width={120} src={item.image} alt='post_image' />
                  </TableCell>
                  <TableCell>
                    <Box sx={flex}>
                    <IconButton
                        color='warning'
                        component='span'
                        aria-label="Info"
                        title={`Update post data for ${item.title}`}
                        >
                          <BorderColorIcon onClick={() => {
                        setOpen(true);
                        setInfo(item);
                        }} 
                        sx={btnHoverStyle} 
                        />
                        </IconButton>
                        <IconButton
                        color='danger'
                        component='span'
                        aria-label="Info"
                        title={`Delete member data for ${item.first_name} ${item.last_name}`}
                        ><DeleteForeverIcon onClick={() => deletePost(item.id)} sx={btnHoverStyle} /></IconButton>
                        
                    </Box>
                  
                </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default PostsTable;