import { useEffect, useState } from "react";
import usePostCall from "../../hooks/usePostCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PostModal from "../modals/PostModal";
import PostsTable from "../tables/PostsTable";


const Posts = () => {
  const { getPosts } = usePostCall();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});
  

  useEffect(() => {
    getPosts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Posts
      </Typography>

      <Button className="w-25" variant="contained" onClick={() => setOpen(true)}>
        New Post
      </Button>

      

      <PostModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      />

      <PostsTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  );
}

export default Posts

