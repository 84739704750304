import { useDispatch } from "react-redux";
import {fetchStart,fetchFail,loadData} from '../features/boardSlice';
import useAxios, { axiosPublic } from "./useAxios";
import {toastSuccessNotify} from '../helper/ToastNotify';

const useBoardCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();

    const getBoard = async()=>{
        dispatch(fetchStart())
        try {
            const {data} = await axiosPublic.get('api/board/');
            dispatch(loadData(data))
        } catch (error) {
            dispatch(fetchFail())
            console.log(error)
        }
    }

    const addBoard = async(info) => {
      dispatch(fetchStart());
      try {
        const {data} = await axiosWithToken.post('api/board/', info);
        toastSuccessNotify(`${data.name} created successfully`)
        getBoard();

      } catch (error) {
        console.log(error);
      }
    }
    const updateBoard = async(id, info) => {
      dispatch(fetchStart());
      try {
        const {data} = await axiosWithToken.put(`api/board/${id}/`, info);
        toastSuccessNotify(`${data.name} updated successfully`)
        getBoard();

      } catch (error) {
        console.log(error);
      }
    }

    const deleteBoard = async(id) => {
      dispatch(fetchStart());
      try {
        await axiosWithToken.delete(`api/board/${id}/`);
        toastSuccessNotify(`board with id:${id} deleted successfully`)
        getBoard();

      } catch (error) {
        console.log(error);
      }
    }

  return {
    getBoard,
    addBoard,
    updateBoard,
    deleteBoard
  }
}

export default useBoardCall