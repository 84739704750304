import React from "react";
import Grid from '@mui/material/Grid';
import "./PartnerLogos.css";

const PartnerLogos = ({partners}) => {
  return (
    <Grid container spacing={0} className="partner-logos-container">
        {partners?.map((partner) => {
            return(
              <Grid key={partner.id} item xs={2} className="partner-logo">
        <img src={partner.logoImage} alt={partner.name} style={{ maxWidth: "90px" }} />
        
      </Grid>  
            )
        })}
      
    </Grid>
  );
};

export default PartnerLogos;
