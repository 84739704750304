import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Paper
} from '@mui/material';
import { arrowStyle, btnHoverStyle, flex } from "../../styles/globalStyle";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import { useSelector } from 'react-redux';


const  FooterTable = ({setOpen, setInfo}) => {
  const {footerData} = useSelector((state) => state.footer);


  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Footer Detail</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows footer detail"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  info Email
                </Typography>
              </Box>
              </TableCell>
              {/* <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Facebook Link
                </Typography>
              </Box>
              </TableCell><TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Instagram Link
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Twitter Link
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Linkedin Link
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Youtube Link
                </Typography>
              </Box>
              </TableCell> */}
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                    Phone Number
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Motto
                </Typography>
              </Box>
              </TableCell>

              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                   Address
                </Typography>
              </Box>
              </TableCell>
              
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
                
                <TableRow key={footerData[0]?.id}>
                  <TableCell align='center'>{footerData[0]?.infoMail}</TableCell>
                  {/* <TableCell align='center'>{footerData[0]?.facebookLink}</TableCell>
                  <TableCell align="center">{footerData[0]?.instagramLink}</TableCell>
                  <TableCell align="center">{footerData[0]?.twitterLink}</TableCell>
                  <TableCell align="center">{footerData[0]?.linkedinLink}</TableCell>
                  <TableCell align="center">{footerData[0]?.youtubeLink}</TableCell> */}
                  <TableCell align="center">{footerData[0]?.phoneNumber}</TableCell>
                  <TableCell align="center">{footerData[0]?.description}</TableCell>
                  <TableCell align="center">{footerData[0]?.address}</TableCell>
                  
                  <TableCell>
                    <Box sx={flex}>
                    <IconButton
                        color='warning'
                        component='span'
                        aria-label="Info"
                        title={`Update footer data for ${footerData[0]?.infoMail}`}
                        >
                          <BorderColorIcon onClick={() => {
                        setOpen(true);
                        setInfo(footerData[0] && footerData[0]);
                        }} 
                        sx={btnHoverStyle} 
                        />
                        </IconButton>
                    </Box>
                  
                </TableCell>
                </TableRow>
              
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default FooterTable;