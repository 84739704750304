import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    boardData: [],
    loading:false,
    error:false,
}

const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, {payload}) => {
        state.boardData = payload;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
  }
});

export const {fetchStart,fetchFail,loadData} = boardSlice.actions

export default boardSlice.reducer