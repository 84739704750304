import React, {useEffect} from 'react';
import CarouselHome from '../components/CarouselHome';
import UpcomingEvents from '../components/UpcomingEvents';
import usePostCall from '../hooks/usePostCall';
import usePartnerCall from '../hooks/usePartnerCall';
import useEventCall from '../hooks/useEventCall';
import {useSelector} from 'react-redux';
import PartnerLogos from '../components/PartnerLogos';
import {Helmet} from 'react-helmet';

const Home = () => {

  const {posts} = useSelector((state) => state.post);
  const {partners} = useSelector((state) => state.partner);
  const {events} = useSelector((state) => state.event);
  //console.log(postList);

  const {getPosts} = usePostCall();
  const {getPartners} = usePartnerCall();
  const {getEvents} = useEventCall();

  useEffect(() => {
    getPosts();
    getPartners();
    getEvents();
    window.scrollTo(0,0);
  }, [])

  const today = new Date();
  const oneMonthLater = new Date(today);
  oneMonthLater.setMonth(today.getMonth() + 1);
  

  const filteredEvents = events?.filter((event) => {
    const eventDate = new Date(event.dateJs);
    return eventDate >= today && eventDate <= oneMonthLater;
  })

  return (
    <>
    <Helmet>
      <title>Hem</title>
      <meta name='description' content='Hem' />
    </Helmet>
    {/**Carousel  START */}
    <section className='carousel-home'>
      <div className='container mt-100 mx-auto w-100'>
        <div className="row">
        <CarouselHome posts={posts} />
        </div>
      </div>
    </section>
    {/**Carousel  END */}

    {/**UPCOMING EVENTS START */}
    <section className="upcoming-events">
      <div className="container mt-40">
        <UpcomingEvents events={filteredEvents} />
      </div>
    </section>
    {/**UPCOMING EVENTS END */}

    {/**PARTNER LOGOS START */}
    <section className="partner-logos">
      <div className="container mt-40">
        <PartnerLogos partners={partners}/>
      </div>
    </section>
    {/**PARTNER LOGOS START */}
    </>
    
    
    
  )
}

export default Home