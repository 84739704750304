import { useEffect, useState } from "react";
import useEvenemangCall from "../../hooks/useEvenemangCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//import Button from "@mui/material/Button";
//import ParticipantModal from "../modals/ParticipantModal";
import ParticipantsTable from "../tables/ParticipantsTable";

const Participants = () => {

    const { getEvenemangs} = useEvenemangCall();
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState({});
    
  
    useEffect(() => {
      getEvenemangs();
    }, []);

  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Participants
      </Typography>

      {/* <Button className="w-25" variant="contained" onClick={() => setOpen(true)}>
        New Participant
      </Button> */}

      

      {/* <ParticipantModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      /> */}

      <ParticipantsTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  )
}

export default Participants