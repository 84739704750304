import { useEffect, useState } from "react";
import useEventCall from "../../hooks/useEventCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EventModal from "../modals/EventModal";
import EventsTable from "../tables/EventsTable";


const Events = () => {

  
  const { getEvents} = useEventCall();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    getEvents();
  }, []);


  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Events
      </Typography>

      <Button className="w-25" variant="contained" onClick={() => setOpen(true)}>
        New Event
      </Button>

      

      <EventModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      />

      <EventsTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  )
}

export default Events
