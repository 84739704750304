import { useEffect, useState } from "react";
import useBoardCall from "../../hooks/useBoardCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import BoardModal from "../modals/BoardModal";
import BoardsTable from "../tables/BoardsTable";

const Boards = () => {

  const { getBoard} = useBoardCall();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    getBoard();
  }, []);


  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Boards
      </Typography>

      <Button className="w-25" variant="contained" onClick={() => setOpen(true)}>
        New Board
      </Button>

      

      <BoardModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      />

      <BoardsTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  )
}

export default Boards
