import { useEffect, useState } from "react";
import useMemberCall from "../../hooks/useMemberCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MemberModal from "../modals/MemberModal";
import MembersTable from "../tables/MembersTable";

const Members = () => {
  const { getMembers} = useMemberCall();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});
  

  useEffect(() => {
    getMembers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Members
      </Typography>

      <Button className="w-25" variant="contained" onClick={() => setOpen(true)}>
        New Member
      </Button>

      

      <MemberModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      />

      <MembersTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  );
};

export default Members;
