import React from "react";
import { flexColumn, modalStyle2 } from "../../styles/globalStyle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
//import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import useFooterCall from "../../hooks/useFooterCall";
import { Grid } from "@mui/material";

export default function FooterModal({ open, setOpen, info, setInfo }) {
  const { updateFooter } = useFooterCall();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    if (info.id) {
      updateFooter(info);
    } else {
      console.log("hi")
    }
    setOpen(false);
    setInfo({});
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setInfo({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle2}>
        <Box sx={flexColumn} component={"form"} onSubmit={handleSubmit}>
          
          <Grid container spacing={3}> 
            <Grid item sx={6}>
          <TextField
            label="Info Email"
            name="infoMail"
            id="infoMail"
            type="text"
            variant="outlined"
            value={info?.infoMail || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Facebook Link"
            name="facebookLink"
            id="facebookLink"
            type="text"
            variant="outlined"
            value={info?.facebookLink || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Instagram Link"
            name="instagramLink"
            id="instagramLink"
            type="text"
            variant="outlined"
            value={info?.instagramLink || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Twitter Link"
            name="twitterLink"
            id="twitterLink"
            type="text"
            variant="outlined"
            value={info?.twitterLink || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Linkedin Link"
            name="linkedinLink"
            id="linkedinLink"
            type="text"
            variant="outlined"
            value={info?.linkedinLink || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Youtube Link"
            name="youtubeLink"
            id="youtubeLink"
            type="text"
            variant="outlined"
            value={info?.youtubeLink || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Phone number"
            name="phoneNumber"
            id="phoneNumber"
            type="text"
            variant="outlined"
            value={info?.phoneNumber || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Motto"
            name="description"
            id="description"
            type="text"
            variant="outlined"
            value={info?.description || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Address"
            name="address"
            id="address"
            type="text"
            variant="outlined"
            value={info?.address || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            
            
          </Grid>
          <Button type="submit" variant="contained" size="large">
          {info?.id ? "Update Footer" : "Add New Footer"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
