
import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import useSubscribeCall from '../../hooks/useSubscribeCall';
import Paper from '@mui/material/Paper';
import { Button, TextField, Container, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { btnHoverStyle } from "../../styles/globalStyle";
  

const columns = [
  { id: 'id', label: '#', minWidth: 100, align: 'left',
  format: (value) => value.toLocaleString('en-US'), },
  { id: 'email', label: 'Email', minWidth: 170, align: 'left',
  format: (value) => value.toLocaleString('en-US'), },
];

export default function Subscribers() {

  const {getSubscribers, deleteSubscriber, sendInformationToAllSubscribers} = useSubscribeCall();
    const {subscribers} = useSelector((state) => state.subscribe);
    
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [formData, setFormData] = useState({
    title: '',
    content: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendInformationToAllSubscribers(formData)
    console.log(formData);
  };


  

    useEffect(() => {
      getSubscribers();
    }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container>
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontSize:"32px", fontWeight:'600'}} align='center' colSpan={5}>Subscribers</TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell>Operations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscribers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <DeleteForeverIcon sx={btnHoverStyle} onClick={() => deleteSubscriber(row.id)} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={subscribers?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    <Typography align='center' mt={2} sx={{textTransform:'uppercase', border: '2px solid gray', borderRadius:'4px'}} variant="h4" component="h2">Send information mail to all subscribers</Typography>
    <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Content"
          name="content"
          value={formData.content}
          onChange={handleInputChange}
          multiline
          rows={4}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Container>
  );
}

