import React from "react";
import { flexColumn, modalStyle2 } from "../../styles/globalStyle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
//import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import useContactFormCall from "../../hooks/useContactFormCall";
import { Grid } from "@mui/material";

export default function MessageModal({ open, setOpen, info, setInfo }) {
  const { updateMessage } = useContactFormCall();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    if (info.id) {
      updateMessage(info);
    } else {
      console.log("hi")
    }
    setOpen(false);
    setInfo({});
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setInfo({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle2}>
        <Box sx={flexColumn} component={"form"} onSubmit={handleSubmit}>
          
          <Grid container spacing={3}>
            <Grid item sx={6} >
              <TextField
            label="Name"
            name="name"
            id="name"
            type="text"
            variant="outlined"
            value={info?.name || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            
            <Grid item sx={6}>
          <TextField
            label="Email"
            name="email"
            id="email"
            type="text"
            variant="outlined"
            value={info?.email || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            
          <Grid item sx={6}>
      <FormControl fullWidth required>
        <InputLabel>Status</InputLabel>
        <Select
        style={{width: "120px"}}
          name="status"
          value={info?.status || ""}
          onChange={handleChange}
        >
          <MenuItem value="true">Read</MenuItem>
          <MenuItem value="false">Unread</MenuItem>
        </Select>
      </FormControl>
      </Grid>
            <Grid item sx={6}><TextField
        label="Message"
        name="message"
        value={info?.message || ""}
        onChange={handleChange}
        type="text"
        multiline
        rows={4}
        fullWidth
        required
      />
      
      </Grid>
            
          </Grid>
          <Button type="submit" variant="contained" size="large">
          {info?.id ? "Update Member" : "Add New Member"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
