import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import EmailIcon from '@mui/icons-material/Email';

const cardStyle = {
  width: '18rem',
};


const emailLinkStyle = {
  color: '#c62026',
  fontSize: '22px',
  textDecoration: 'none',
};

function BoardsCard({ board }) {
  return (
    <Card style={cardStyle}>
      <CardMedia component="img" height="auto" src={board.image} alt={board.name} />
      <CardContent>
        <Typography variant="h5" component="div">
          {board.name}{' '}
          <Chip label={board.title} variant="outlined" />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {board.motto}
        </Typography>
        <a href={`mailto:${board.email}`} role="button" style={emailLinkStyle}>
          <EmailIcon />
        </a>
      </CardContent>
    </Card>
  );
}

export default BoardsCard;

