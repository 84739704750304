import React, {useState} from "react";
import { flexColumn, modalStyle2 } from "../../styles/globalStyle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Badge, IconButton} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import Modal from "@mui/material/Modal";
import {TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
//import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import useEventCall from "../../hooks/useEventCall";
import { Grid } from "@mui/material";

export default function EventModal({ open, setOpen, info, setInfo }) {
  const { addEvent, updateEvent } = useEventCall();
  const [image, setImage] = useState(null);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    const formData = new FormData();
    formData.append('eventName', info?.eventName);
    formData.append('eventDesc', info?.eventDesc);
    formData.append('eventOrganizer', info?.eventOrganizer);
    formData.append('eventDate', info?.eventDate);
    formData.append('eventLocation', info?.eventLocation);
    formData.append('eventPrice', info?.eventPrice);
    formData.append('eventHasForm', info?.eventHasForm);
    formData.append('eventImage', image || "");
    if (info.id) {
      updateEvent(info.id, formData);
    } else {
      addEvent(formData);
    }
    setOpen(false);
    setInfo({});
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setInfo({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle2}>
        <Box sx={flexColumn} component={"form"} onSubmit={handleSubmit}>
          
          <Grid container spacing={3}>
            <Grid item sx={6} >
              <TextField
            margin="dense"
            label="Event Name"
            name="eventName"
            id="eventName"
            type="text"
            variant="outlined"
            value={info?.eventName || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            
            <Grid item sx={6}>
          <TextField
            margin="dense"
            label="Event Organizer"
            name="eventOrganizer"
            id="eventOrganizer"
            type="text"
            variant="outlined"
            value={info?.eventOrganizer || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            <Grid item sx={6}>
            <Badge badgeContent={<IconButton
          color="warning"
          aria-label="Info"
          component="span"
          title=""
        >
          <InfoIcon />
        </IconButton>} sx={{color:"red"}}>
            <TextField
          label="Event Date"
          name="eventDate"
          type="date"
          value={info?.eventDate || ""}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          />
          </Badge>
            </Grid>
            <Grid item sx={6}>
          <TextField
            margin="dense"
            label="Event Location"
            name="eventLocation"
            id="eventLocation"
            type="text"
            variant="outlined"
            value={info?.eventLocation || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            <Grid item sx={6}>
            <FormControl fullWidth required>
        <InputLabel>Event Has Form</InputLabel>
        <Select
        style={{width: "120px"}}
          name="eventHasForm"
          value={info?.eventHasForm || ""}
          onChange={handleChange}
        >
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </FormControl>
            </Grid>
            <Grid item sx={6}><TextField
            label="Event Price"
            name="eventPrice"
            id="eventPrice"
            type="text"
            variant="outlined"
            value={info?.eventPrice || ""}
            onChange={handleChange}
            required
            fullWidth
          /></Grid>

<Grid item sx={6}>
            <TextField
            margin="dense"
            label="Event Description"
            name="eventDesc"
            id="eventDesc"
            type="text"
            multiline
            rows={4}
            variant="outlined"
            value={info?.eventDesc || ""}
            onChange={handleChange}
            required
          />
            </Grid>

          <Grid item sx={6} >
            <Badge badgeContent={<IconButton
          color="info"
          aria-label="Info"
          component="span"
          title="Add event image"
        >
          <InfoIcon />
        </IconButton>} sx={{color:"#fff"}}>
                <TextField
            name="eventImage"
            id="eventImage"
            type="file"
            multiple
            accept="image/*"
            variant="outlined"
            // value={info?.eventImage || ""}
            onChange={(e) => {
              setImage(e.target.files[0])
            }}
          />
            </Badge>
              
            </Grid>
            
          </Grid>
          <Button type="submit" variant="contained" size="large">
          {info?.id ? "Update Event" : "Add New Event"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
