import React from "react";
import { flexColumn, modalStyle2 } from "../../styles/globalStyle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
//import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import useMemberCall from "../../hooks/useMemberCall";
import { Grid } from "@mui/material";

export default function MemberModal({ open, setOpen, info, setInfo }) {
  const { addMember, updateMember } = useMemberCall();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    if (info.id) {
      updateMember(info);
    } else {
      addMember(info);
    }
    setOpen(false);
    setInfo({});
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setInfo({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle2}>
        <Box sx={flexColumn} component={"form"} onSubmit={handleSubmit}>
          
          <Grid container spacing={3}>
            <Grid item sx={6} >
              <TextField
            margin="dense"
            label="First Name"
            name="first_name"
            id="first_name"
            type="text"
            variant="outlined"
            value={info?.first_name || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            <Grid item sx={6}>
            <TextField
            margin="dense"
            label="Last Name"
            name="last_name"
            id="last_name"
            type="text"
            variant="outlined"
            value={info?.last_name || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            <Grid item sx={6}>
          <TextField
            margin="dense"
            label="Email"
            name="email"
            id="email"
            type="text"
            variant="outlined"
            value={info?.email || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            <Grid item sx={6}>
            <TextField
          label="Birthday"
          name="birthday"
          type="date"
          value={info?.birthday || ""}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          />
            </Grid>
            <Grid item sx={6}>
            <FormControl fullWidth required>
        <InputLabel>Kön</InputLabel>
        <Select
        style={{width: "120px"}}
          name="gender"
          value={info?.gender || ""}
          onChange={handleChange}
        >
          <MenuItem value="M">Han</MenuItem>
          <MenuItem value="F">Hon</MenuItem>
          <MenuItem value="O">Hen</MenuItem>
        </Select>
      </FormControl>
            </Grid>
            <Grid item sx={6}><TextField
            label="Phone"
            name="phone_number"
            id="phone_number"
            type="text"
            variant="outlined"
            value={info?.phone_number || ""}
            onChange={handleChange}
            required
            fullWidth
          /></Grid>
          <Grid item sx={6}>
      <FormControl fullWidth required>
        <InputLabel>Status</InputLabel>
        <Select
        style={{width: "120px"}}
          name="member_status"
          value={info?.member_status || ""}
          onChange={handleChange}
        >
          <MenuItem value="true">Active</MenuItem>
          <MenuItem value="false">Passive</MenuItem>
        </Select>
      </FormControl>
      </Grid>
            <Grid item sx={6}><TextField
        label="Address"
        name="address"
        value={info?.address || ""}
        onChange={handleChange}
        
        fullWidth
        required
      />
      
      </Grid>
            <Grid item sx={6}>
            <TextField
        label="Zip Code"
        name="zip_code"
        value={info?.zip_code || ""}
        onChange={handleChange}
        
        fullWidth
        required
      />
            </Grid>
            <Grid item sx={6}><TextField
        label="City"
        name="city"
        value={info?.city || ""}
        onChange={handleChange}
        
        fullWidth
        required
      /></Grid>
          </Grid>
          <Button type="submit" variant="contained" size="large">
          {info?.id ? "Update Member" : "Add New Member"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
