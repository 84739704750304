
import { useEffect, useState } from "react";
import useContactFormCall from "../../hooks/useContactFormCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MessageModal from "../modals/MessageModal";
import MessagesTable from "../tables/MessagesTable";

  

const Messages = () => {

  const { getMessages } = useContactFormCall();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    getMessages();
  }, []);


  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Messages
      </Typography>

      

      <MessageModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      />

      <MessagesTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  )
}

export default Messages
