import {fetchStart, fetchFail, loadData, fetchEnd} from '../features/subscribeSlice';
import { useDispatch } from 'react-redux';
import useAxios, { axiosPublic } from './useAxios';
import {toastErrorNotify, toastSuccessNotify} from '../helper/ToastNotify';

const useSubscribeCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();


    const getSubscribers = async() => {
        dispatch(fetchStart())
        try {
            const {data} = await axiosWithToken.get('api/subscribers/')
            dispatch(loadData(data));
            //console.log(data);
        } catch (error) {
            dispatch(fetchFail())
            console.log(error);
        }
    }
    const addSubscriber = async(info) => {
        dispatch(fetchStart())
            try {
            const {data} = await axiosPublic.post('api/subscribers/', info)
            //dispatch(loadData(data));
            //console.log(data);
            toastSuccessNotify(`Din e-post (${data.email}) har lagts till i prenumerantlistan.`)
            getSubscribers();
            dispatch(fetchEnd());
        } catch (error) {
            dispatch(fetchFail())
            toastErrorNotify(`Din e-postadress är redan registrerad.`)
        }
        
    }


    const deleteSubscriber = async(id) => {
        dispatch(fetchStart());
        try {
             await axiosWithToken.delete(`api/subscribers/${id}/`)
            //console.log(data);
            getSubscribers();
            toastSuccessNotify(`data with id:${id} deleted from our data succesfully`)
        } catch (error) {
            dispatch(fetchFail())
            toastErrorNotify(`email can not delete from our data something went wrong!!!`);
        }
    }

    const sendInformationToAllSubscribers = async(info) => {
        dispatch(fetchStart())
            try {
             await axiosWithToken.post('api/send_info_mail/', info)
            //dispatch(loadData(data));
            //console.log(data);
            toastSuccessNotify(`Oldu`)
            dispatch(fetchEnd());
        } catch (error) {
            dispatch(fetchFail())
            toastErrorNotify(`Something went wrong!!!`)
        }
    }

  return {getSubscribers, addSubscriber, deleteSubscriber, sendInformationToAllSubscribers}
}

export default useSubscribeCall