import React, {useEffect} from 'react'
import { Container, Typography, Box } from '@mui/material';
import ContactForm, {contactSchema} from '../components/forms/ContactForm';
import {Link} from 'react-router-dom';
import { Formik } from "formik";
import useContactFormCall from "../hooks/useContactFormCall";
import useKontaktCall from "../hooks/useKontaktCall";
import BankAccountCards from '../components/BankAccountCards';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const Contact = () => {

  const { sendContactForm } = useContactFormCall();
  const { getKontaktData } = useKontaktCall();
  const {kontaktData} = useSelector((state) => state.kontakt);


  useEffect(() => {
    getKontaktData();
  }, [])
  

  return (
    <>
    <Helmet>
      <title>Kontakt</title>
      <meta name='description' content='This is the contact page' />
    </Helmet>
    <Container maxWidth="md" className='mt-100'>
      <Typography style={{
            "fontFamily":"Muli-SemiBold",
            "textTransform":"uppercase",
            "letterSpacing": "7px",
            "border": "1px solid #dad8e4",
            "display": "block",
            "padding": "0 10px",
            "marginBottom": "10px",
            "borderRadius": "6px"
          }} variant="h4" color="primary" align="center">
        {kontaktData[0]?.write_to_us}
      </Typography>
      <Typography variant="body1" align="center">
      {kontaktData[0]?.wtu_content}
      </Typography>
      <Typography style={{
            "fontFamily":"Muli-SemiBold",
            "textTransform":"uppercase",
            "letterSpacing": "7px",
            "border": "1px solid #dad8e4",
            "display": "block",
            "padding": "0 10px",
            "marginBottom": "10px",
            "marginTop": "20px",
            "borderRadius": "6px"
          }} variant="h4" color="primary" align="center">
      {kontaktData[0]?.become_member}
      </Typography>
      <Typography variant="body1" align="center">
      {kontaktData[0]?.bm_content}
        <br />
        Du kan bli medlem genom att fylla i och skicka medlemskapsformuläret här:{' '}
        <Link to="/kontakt/medlem" underline="none">
          <b>Bli medlem</b>
        </Link>
      </Typography>
      <BankAccountCards kontaktData={kontaktData ? kontaktData[0] : ""} />
      <Box sx={{ mt: 4 }}>
      <Typography style={{
            "fontFamily":"Muli-SemiBold",
            "textTransform":"uppercase",
            "letterSpacing": "5px",
            "border": "1px solid #dad8e4",
            "display": "block",
            "padding": "0 10px",
            "marginBottom": "10px",
            "marginTop": "20px"
          }} variant="h6" color="white" sx={{backgroundColor:'gray', borderRadius:'6px'}} align="center">
      Kontakta oss för frågor
      </Typography>
      <Formik
            initialValues={{
              name: "",
              email: "",
            message: ""}}
            validationSchema={contactSchema}
            onSubmit={(values, actions) => {
              sendContactForm(values);
              actions.resetForm();
              actions.setSubmitting(false);
            }}
            component={(props) => <ContactForm {...props} />}
          ></Formik>
      </Box>
    </Container>
    </>
  )
}

export default Contact
