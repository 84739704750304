import React from "react";
import { flexColumn, modalStyle2 } from "../../styles/globalStyle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {TextField} from "@mui/material";
//import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import useAboutCall from "../../hooks/useAboutCall";
import { Grid } from "@mui/material";

export default function AboutModal({ open, setOpen, info, setInfo }) {
  const { updateAbout } = useAboutCall();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    if (info.id) {
      updateAbout(info);
    } else {
      console.log("hi")
    }
    setOpen(false);
    setInfo({});
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setInfo({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle2}>
        <Box sx={flexColumn} component={"form"} onSubmit={handleSubmit}>
          
          <Grid container spacing={3}> 
            <Grid item sx={6}>
          <TextField
            label="Vision Title"
            name="visionTitle"
            id="visionTitle"
            type="text"
            variant="outlined"
            value={info?.visionTitle || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Mission Title"
            name="missionTitle"
            id="missionTitle"
            type="text"
            variant="outlined"
            value={info?.missionTitle || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Board Title"
            name="boardTitle"
            id="boardTitle"
            type="text"
            variant="outlined"
            value={info?.boardTitle || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Vission"
            name="vision"
            id="vision"
            type="text"
            multiline
            rows={6}
            variant="outlined"
            value={info?.vision || ""}
            onChange={handleChange}
            required
          />
            </Grid>

           

            <Grid item sx={6} >
              <TextField
            label="Mission"
            name="mission"
            id="mission"
            type="text"
            multiline
            rows={6}
            variant="outlined"
            value={info?.mission || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            

            <Grid item sx={6} >
              <TextField
            label="Statute"
            name="statute"
            id="statute"
            type="text"
            multiline
            rows={6}
            variant="outlined"
            value={info?.statute || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            
            
          </Grid>
          <Button type="submit" variant="contained" size="large">
          {info?.id ? "Update About" : "Add New About"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
