import React, { useEffect, useState } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Typography, IconButton, Link as MuiLink } from '@mui/material';

import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import './Footer.css';
import { Formik } from 'formik';

import InfoIcon from '@mui/icons-material/Info';

import { useSelector } from 'react-redux';
import useFooterCall from '../hooks/useFooterCall';
import useSubscribeCall from '../hooks/useSubscribeCall';
import LogoPng from './LogoPng';
import NewsletterForm, {NewsletterSchema} from './forms/NewsletterForm';



const Footer = () => {
  const { getFooter } = useFooterCall();
  const {addSubscriber} = useSubscribeCall();
  const { footerData } = useSelector((state) => state.footer);
  const [isTouched, setTouched] = useState(false);

  useEffect(() => {
    getFooter();
  }, []);

  //console.log(footerData[0]);

  const handleTouchStart = () => {
    setTouched(true);

    // Add any additional actions you want to perform on touch
  };

  const handleTouchEnd = () => {
    setTouched(false);
  };

  return (
    <footer>
      <div className="top">
        <div className="pages">
      
      <div className='me-4'>
        {/* Left side for logo */}
        <LogoPng/>
            <Typography variant="body2" className="mt-3" >
              {footerData[0]?.description}
            </Typography>
      </div>
      <div className='mt-3'>
        {/* Right side for address, mail, and telephone */}
        <Typography variant="body2" gutterBottom>
          {footerData[0]?.address}
        </Typography>
        <Typography variant="body2" gutterBottom>
        <MuiLink style={{'textDecoration':'none'}} href={`mailto:${footerData[0]?.infoMail}`} color="inherit">
                <MailIcon />: {footerData[0]?.infoMail}
              </MuiLink>
        </Typography>
        <Typography variant="body2">
          <PhoneIcon /> {footerData[0]?.phoneNumber}
        </Typography>
      </div>
    
    </div>
        <div className="newsletter">
        <h3>Hålla kontakten
        <div
      style={{ position: 'relative', display: 'inline-block' }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <IconButton
        style={{ color: isTouched ? 'red' : 'white' }}
        aria-label="Info"
        component="span"
        title="Om du vill bli informerad om föreningens verksamhet kan du gå med på maillistan."
      >
        <InfoIcon />
      </IconButton>
      {isTouched && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '8px',
            borderRadius: '4px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '1000',
          }}
        >
          Om du vill bli informerad om föreningens verksamhet kan du gå med på maillistan.
        </div>
      )}
    </div>
          {/* <IconButton
          style={{ color: isHovered ? 'red' : 'white' }}
          aria-label="Info"
          component="span"
          title="Om du vill bli informerad om föreningens verksamhet kan du gå med på maillistan."
          onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
        >
          <InfoIcon />
        </IconButton>*/}</h3> 
        <Formik
            initialValues={{email: ""}}
            validationSchema={NewsletterSchema}
            onSubmit={(values, actions) => {
              addSubscriber(values);
              actions.resetForm();
              actions.setSubmitting(false);
            }}
            component={(props) => <NewsletterForm {...props} />}
          ></Formik>
          {/* <form>
          <div className="input-group mb-3">
  <input type="email" value={subscriber} className="form-control" placeholder="E-post" aria-label="Epost" aria-describedby="button-addon2"/>
  <button onClick={() => addSubscriber(subscriber)} className="btn btn-outline-secondary text-white" type="button" id="button-addon2">Skicka</button>
</div>
          </form> */}
        </div>
      </div>
      <div className="social">
      <div>
          <MuiLink href={footerData[0]?.facebookLink} ><BottomNavigationAction label="Facebook" icon={<FacebookIcon />} /></MuiLink>
          <MuiLink href={footerData[0]?.youtubeLink}><BottomNavigationAction label="YouTube" icon={<YouTubeIcon />} /></MuiLink>
          <MuiLink href={footerData[0]?.instagramLink}><BottomNavigationAction label="Instagram" icon={<InstagramIcon />} /></MuiLink>
        </div>
      </div>
      <div className="info">
  &copy; {new Date().getFullYear()} Designed by H02c4n. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;

