import { useEffect, useState } from "react";
import usePartnerCall from "../../hooks/usePartnerCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PartnerModal from "../modals/PartnerModal";
import PartnersTable from "../tables/PartnersTable";

const Partners = () => {

  const { getPartners} = usePartnerCall();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    getPartners();
  }, []);


  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Partners
      </Typography>

      <Button className="w-25" variant="contained" onClick={() => setOpen(true)}>
        New Partner
      </Button>

      

      <PartnerModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      />

      <PartnersTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  )
}

export default Partners
