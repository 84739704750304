import {useDispatch} from 'react-redux';
import {axiosPublic} from '../hooks/useAxios';
import { fetchStart, loadData, fetchFail, fetchEnd } from '../features/postSlice';
import useAxios from '../hooks/useAxios';
//import { useNavigate } from 'react-router-dom';
import { toastSuccessNotify, toastErrorNotify } from '../helper/ToastNotify';

const usePostCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();
    //const navigate = useNavigate();



    //! --------------GET CALLS----------------
    const getAll = async (url) => {
        dispatch(fetchStart())
        try {
            const {data} = await axiosPublic.get(`api/${url}/`);
            dispatch(loadData({data, url}))
        } catch (error) {
            dispatch(fetchFail())
            console.log(error);
        }
    }

    const getOne = async (url, id) => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosPublic.get(`api/posts/${id}/`)
            dispatch(loadData({data, url}))
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
        }
    }

    const getPostsByCategory = async (url, categoryId) => {
        dispatch(fetchStart());
        try {
            const { data } = await axiosPublic.get(`api/categories/${categoryId}/${url}/`)
            dispatch(loadData({ data, url }));
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
        }
    }


    const getPosts = () => getAll('posts');
    const getCurrentPost = (id) => getOne('currentPost', id);
    const getCategories = () => getAll('categories');
    const updateCategory = (categoryId) => getPostsByCategory('posts', categoryId)



    //! --------------POST CALLS----------------
    

    const sendPost = async (url, info, message) => {
        dispatch(fetchStart())
        try {
            await axiosWithToken.post(`api/${url}/`, info);
            getPosts();
            dispatch(fetchEnd());
            toastSuccessNotify(message);
            //navigate("/admin/posts/");
        } catch (error) {
            console.log(error);
            toastErrorNotify(error);
        }
    }

    const addNewCategory = async (info) => {
        try {
            await axiosWithToken.post(`api/categories/`, info);
            toastSuccessNotify(`Category ${info?.name} added!!!`);
            getPosts();
        } catch (error) {
            console.log(error);
        }
    }

    const addPost = (info) => sendPost('posts', info, `Post ${info?.title} is created successfully`);


    //! --------------UPDATE CALLS----------------

    const updatePost = async (id, info) => {
        try {
            await axiosWithToken.put(`api/posts/${id}/`, info);
            toastSuccessNotify('post is updated successfuly!');
            getPosts();
        } catch (error) {
            console.log(error);
        }
    }


    //! --------------DELETE CALLS----------------

    const deletePost = async (id) => {
        try {
            await axiosWithToken.delete(`api/posts/${id}/`);

            toastSuccessNotify('post is deleted successfuly!');
            getPosts();
        } catch (error) {
            toastErrorNotify('Something went wrong!!!');
            console.log(error);
        }
    }


  return {getPosts, getCurrentPost, getCategories, updateCategory,addNewCategory, addPost, updatePost, deletePost}
}

export default usePostCall