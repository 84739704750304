import {
    TextField,
    Box,
    Paper
  } from '@mui/material';
  import LoadingButton from "@mui/lab/LoadingButton";
  
  import { Form } from "formik";
  import { useSelector } from "react-redux";
  import * as Yup from "yup";




export const NewsletterSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid email")
      .required("E-post är obligatoriskt.")
  });



const NewsletterForm = ({ values, handleChange, errors, touched, handleBlur }) => {
    const { loading } = useSelector((state) => state.subscribe);
    return (
      <Form>
        <Paper elevation={3}>
        <Box sx={{ display: "flex" }}>
        
        <TextField
          label="E-Post"
          name="email"
          type="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.email && errors.email}
          error={touched.email && Boolean(errors.email)}
          fullWidth
          required
        />
        
          <LoadingButton
            loading={loading}
            loadingPosition="center"
            variant="contained"
            sx={{backgroundColor: '#061a37'}}
            type="submit"
          >
            Skicka
          </LoadingButton>
        </Box>
        </Paper>
      </Form>
    );
}

export default NewsletterForm
  
  