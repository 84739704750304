import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Paper
} from '@mui/material';
import { btnHoverStyle, flex } from "../../styles/globalStyle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import usePartnerCall from "../../hooks/usePartnerCall";
import { useSelector } from 'react-redux';


const  PartnersTable = ({setOpen, setInfo}) => {
  const {partners} = useSelector((state) => state.partner);
  const { deletePartner} = usePartnerCall();


  


  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Boards Info</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows boards information"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(partners) && partners?.map((item) => (
                
                <TableRow key={item.id}>
                  <TableCell align='center'>{item.id}</TableCell>
                  <TableCell align='center'>{item.name}</TableCell>
                  <TableCell align="center">
                  <img width={120} height={120} src={item.logoImage} alt='logo_image' />
                  </TableCell>
                  
                  
                  <TableCell>
                    <Box sx={flex}>
                    <IconButton
                        color='warning'
                        component='span'
                        aria-label="Info"
                        title={`Update partner data for ${item.name}`}
                        >
                          <BorderColorIcon onClick={() => {
                        setOpen(true);
                        setInfo(item);
                        }} 
                        sx={btnHoverStyle} 
                        />
                        </IconButton>
                        <IconButton
                        color='danger'
                        component='span'
                        aria-label="Info"
                        title={`Delete partner data for ${item.name}`}
                        ><DeleteForeverIcon onClick={() => deletePartner(item.id)} sx={btnHoverStyle} /></IconButton>
                    </Box>
                  
                </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default PartnersTable;