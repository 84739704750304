import React from "react";
import EventCard from "./EventCard";
import { useNavigate } from "react-router-dom";


const Events = ({events, title}) => {

  const navigate = useNavigate();


  return (
   
   <section id="courses">
    <div className="container" style={{"maxWidth":"1000px"}}>
      {events.length > 0 && (
        <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="section-title">
            {title ? title : <h2 style={{
            "fontFamily":"Muli-SemiBold",
            "textTransform":"uppercase",
            "letterSpacing": "7px",
            "border": "1px solid #dad8e4",
            "display": "block",
            "padding": "0 10px",
            "marginBottom": "10px",
            "borderRadius": "6px"
          }}  align="center">Kommande Evenemang</h2>}
            
          </div>
          <div className="owl-carousel owl-theme owl-courses">
            {events.map((event) => {
              return <div key={event.id} onClick={() => navigate(`/evenemang/${event.eventName}`, {state:event})}>
                <div style={{"marginBottom": "10px", "cursor":"pointer"}}>
                <EventCard event={event} />
                </div>
              </div> ;
            })}
          </div>
        </div>
      </div>
      )}
    </div>
  </section>
  );
};

export default Events;
