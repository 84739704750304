import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Select,
  MenuItem,
  Checkbox,
  Paper
} from '@mui/material';
//import {MultiSelect, MultiSelectItem, Select, SelectItem} from '@tremor/react';
import BadgeDelta from '@mui/material/Badge';
import useSortColumn from '../../hooks/useSortColumn';
import UpgradeIcon from "@mui/icons-material/Upgrade";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { arrowStyle, btnHoverStyle, flex } from "../../styles/globalStyle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import useEventCall from "../../hooks/useEventCall";
import { useSelector } from 'react-redux';


const  EventsTable = ({setOpen, setInfo}) => {
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedLocations, setSelectedLocations] = useState([]);
  const {events} = useSelector((state) => state.event);
  const { deleteEvent} = useEventCall();


  const columnObj = {
    eventDate: 1,
    eventPrice: 1,
    eventLocation: 1,
    eventHasForm: 1,
    id: 1,
  };

  const { sortedData, handleSort, columns } = useSortColumn(
    events,
    columnObj
    );

  const isEventLocationsSelected = (event) =>
    (`${event.eventHasForm}` === selectedStatus || selectedStatus === 'all') &&
    (selectedLocations?.includes(event.eventLocation) || selectedLocations?.length === 0);

  
  //const uniqueEventLocations = new Set(events?.map((item) => item['eventLocation']));

  const uniqueEventLocations = new Set(Array.isArray(events) ? events?.map((item) => item['eventLocation'])  : [])

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Events Info</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows events information"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <div className='flex space-x-2'>
        <Select
        className='w-50'
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
        <Select
          multiple
          placeholder='Select Event Has Form'
          className="w-50"
          value={selectedLocations}
          onChange={(e) => setSelectedLocations(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
        >

          {Array.isArray([...uniqueEventLocations]) && [...uniqueEventLocations]?.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedLocations?.includes(item)} />
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              <Box sx={arrowStyle} onClick={() => handleSort("id")}>
                <Typography variant="body" noWrap>
                  #
                </Typography>
                {columns.id === 1 && <UpgradeIcon />}
                {columns.id !== 1 && <VerticalAlignBottomIcon />}
              </Box>
              </TableCell>
              <TableCell align="center">Event Name</TableCell>
              <TableCell align="center">Event Organizer</TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("eventDate")}>
                <Typography variant="body" noWrap>
                  Event Date
                </Typography>
                {columns.eventDate === 1 && <UpgradeIcon />}
                {columns.eventDate !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("eventHasForm")}>
                <Typography variant="body" noWrap>
                  Event Has Form
                </Typography>
                {columns.eventHasForm === 1 && <UpgradeIcon />}
                {columns.eventHasForm !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("eventLocation")}>
                <Typography variant="body" noWrap>
                  Event Location
                </Typography>
                {columns.eventLocation === 1 && <UpgradeIcon />}
                {columns.eventLocation !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center">Event Price</TableCell>
              <TableCell align="center">Event Image</TableCell>
              
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedData) && sortedData?.filter((item) => isEventLocationsSelected(item))
              .map((item) => (
                
                <TableRow key={item.id}>
                  <TableCell align='center'>{item.id}</TableCell>
                  <TableCell align='center'>{item.eventName}</TableCell>
                  <TableCell align="center">{item.eventOrganizer}</TableCell>
                  <TableCell align="center">{item.dateJs.split('T')[0]}</TableCell>
                  <TableCell align="center">
                    <BadgeDelta
                      badgeContent={`${item.eventHasForm === true ? "Yes" : "No"}`}
                      color={`${item.eventHasForm === true ? "success" : "error"}`}
                    />
                  </TableCell>
                  <TableCell align="center">{item.eventLocation}</TableCell>
                  <TableCell align="center">{item.eventPrice}</TableCell>
                  <TableCell align="center">
                  <img width={120} src={item.eventImage} alt='event_image' />
                  </TableCell>
                  
                  
                  <TableCell>
                    <Box sx={flex}>
                    <IconButton
                        color='warning'
                        component='span'
                        aria-label="Info"
                        title={`Update event data for ${item.eventName}`}
                        >
                          <BorderColorIcon onClick={() => {
                        setOpen(true);
                        setInfo(item);
                        }} 
                        sx={btnHoverStyle} 
                        />
                        </IconButton>
                        <IconButton
                        color='danger'
                        component='span'
                        aria-label="Info"
                        title={`Delete event data for ${item.eventName}`}
                        ><DeleteForeverIcon onClick={() => deleteEvent(item.id)} sx={btnHoverStyle} /></IconButton>
                    </Box>
                  
                </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default EventsTable;