import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Paper
} from '@mui/material';
import { arrowStyle, btnHoverStyle, flex } from "../../styles/globalStyle";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import { useSelector } from 'react-redux';


const  AboutTable = ({setOpen, setInfo}) => {
  const {aboutData} = useSelector((state) => state.about);


  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">About Detail</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows about detail"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Vision Title
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                    Vision
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Board Title
                </Typography>
              </Box>
              </TableCell>
              
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
                
                <TableRow key={aboutData[0]?.id}>
                  <TableCell align='center'>{aboutData[0]?.visionTitle}</TableCell>
                  <TableCell align="center">{aboutData[0]?.vision}</TableCell>
                  <TableCell align="center">{aboutData[0]?.boardTitle}</TableCell>
    
                  <TableCell>
                    <Box sx={flex}>
                    <IconButton
                        color='warning'
                        component='span'
                        aria-label="Info"
                        title={`Update about data for ${aboutData[0]?.visionTitle}`}
                        >
                          <BorderColorIcon onClick={() => {
                        setOpen(true);
                        setInfo(aboutData[0] && aboutData[0]);
                        }} 
                        sx={btnHoverStyle} 
                        />
                        </IconButton>
                    </Box>
                  
                </TableCell>
                </TableRow>
              
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default AboutTable;