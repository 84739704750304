
import { useDispatch } from 'react-redux';
import { fetchStart, loadData, loadCities, fetchFail } from '../features/memberSlice'; 
import useAxios, { axiosPublic } from './useAxios';
import {toastSuccessNotify, toastErrorNotify} from '../helper/ToastNotify'



const useMemberCall = () => {
    
    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();


    const getMembers = async () => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosWithToken.get('api/members/')
            dispatch(loadData(data))
            let cities = []; 
            cities = data?.map(m => {
                return {
                    city: m.city,
                }
            })
            dispatch(loadCities(cities))
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
            
        }
    }

    const addMember = async (info) => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosPublic.post("api/members/", info)
            dispatch(loadData(data))
            getMembers();
        } catch (error) {
            dispatch(fetchFail());
            toastErrorNotify(error.response.statusText)
            console.log(error);
        }
    }

    const updateMember = async (info) => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosWithToken.put(`api/members/${info.id}/`, info)
            dispatch(loadData(data))
            getMembers();
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
        }
    }


    const deleteMember = async(id) => {
        try {
            await axiosWithToken.delete(`api/members/${id}/`)
            toastSuccessNotify(`member with this ${id} successfuly deleted.`)
            getMembers();
        } catch (error) {
            console.log(error);
            toastErrorNotify(`member with this ${id} can not be deleted.`)
        }
    }


    const send_activation_mail = async(id) => {
        try {
            const {data} = await axiosWithToken.get(`api/send_mail/${id}`)
            toastSuccessNotify(`activation mail sent to ${data}`);
            console.log(data);
        } catch (error) {
            console.log(error)
            
        }
    }
  
    return {getMembers, addMember, updateMember, deleteMember, send_activation_mail}
}

export default useMemberCall