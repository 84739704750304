import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    partners : [],
    loading:false,
    error:false,
}

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, {payload}) => {
        state.partners = payload;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
  }
});

export const {fetchStart, loadData, fetchFail} = partnerSlice.actions

export default partnerSlice.reducer