import React, {useState} from "react";
import { flexColumn, modalStyle2 } from "../../styles/globalStyle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {TextField} from "@mui/material";
//import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import useKontaktCall from "../../hooks/useKontaktCall";
import { Grid } from "@mui/material";

export default function ContactModal({ open, setOpen, info, setInfo }) {
  const { updateContact } = useKontaktCall();

  const [image, setImage] = useState(null);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    const formData = new FormData();
      formData.append('write_to_us', info?.write_to_us);
      formData.append('wtu_content', info?.wtu_content);
      formData.append('become_member', info?.become_member);
      formData.append('bm_content', info?.bm_content);
      formData.append('bank_name', info?.bank_name);
      formData.append('bank_swift', info?.bank_swift);
      formData.append('bank_iban', info?.bank_iban);
      formData.append('bankgiro_name', info?.bankgiro_name);
      formData.append('bankgiro_number', info?.bankgiro_number);
      formData.append('bankkonto_name', info?.bankkonto_name);
      formData.append('bankkonto_number', info?.bankkonto_number);
      formData.append('swish_number', info?.swish_number);
      formData.append('swihs_image', image || "");
    if (info.id) {
      updateContact(info?.id, formData);
    } else {
      console.log("hi")
    }
    setOpen(false);
    setInfo({});
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setInfo({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle2}>
        <Box sx={flexColumn} component={"form"} onSubmit={handleSubmit}>
          
          <Grid container spacing={3}> 
            <Grid item sx={6}>
          <TextField
            label="Write to Us"
            name="write_to_us"
            id="write_to_us"
            type="text"
            variant="outlined"
            value={info?.write_to_us || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Write to Us Content"
            name="wtu_content"
            id="wtu_content"
            type="text"
            variant="outlined"
            value={info?.wtu_content || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Become Member"
            name="become_member"
            id="become_member"
            type="text"
            variant="outlined"
            value={info?.become_member || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Become Member Content"
            name="bm_content"
            id="bm_content"
            type="text"
            variant="outlined"
            value={info?.bm_content || ""}
            onChange={handleChange}
            required
          />
            </Grid>

           

            <Grid item sx={6} >
              <TextField
            label="Bank Name"
            name="bank_name"
            id="bank_name"
            type="text"
            variant="outlined"
            value={info?.bank_name || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            

            <Grid item sx={6} >
              <TextField
            label="Bank Swift"
            name="bank_swift"
            id="bank_swift"
            type="text"
            variant="outlined"
            value={info?.bank_swift || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Bank IBAN"
            name="bank_iban"
            id="bank_iban"
            type="text"
            variant="outlined"
            value={info?.bank_iban || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Bankgiro Name"
            name="bankgiro_name"
            id="bankgiro_name"
            type="text"
            variant="outlined"
            value={info?.bankgiro_name || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Bankgiro Number"
            name="bankgiro_number"
            id="bankgiro_number"
            type="text"
            variant="outlined"
            value={info?.bankgiro_number || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Bankkonto Name"
            name="bankkonto_name"
            id="bankkonto_name"
            type="text"
            variant="outlined"
            value={info?.bankkonto_name || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Bankkonto Number"
            name="bankkonto_number"
            id="bankkonto_number"
            type="text"
            variant="outlined"
            value={info?.bankkonto_number || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Swish Number"
            name="swish_number"
            id="swish_number"
            type="text"
            variant="outlined"
            value={info?.swish_number || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6} >
              <TextField
            label="Swish Image"
            name="swihs_image"
            id="swish_image"
            type="file"
            multiple
            accept="image/*"
            variant="outlined"
            // value={info?.swish_image || ""}
            onChange={(e) => {
              setImage(e.target.files[0])
            }}
          />
            </Grid>

            
            
          </Grid>
          <Button type="submit" variant="contained" size="large">
          {info?.id ? "Update About" : "Add New About"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
