import React from 'react';
import {
  Container,
  Grid,
  Typography
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Formik } from "formik";
import EventFormData, {eventSchema} from './EventFormData';
import useEvenemangCall from '../../hooks/useEvenemangCall';



function EventForm() {

    const {state:event} = useLocation();
    const {addEvenemang} = useEvenemangCall();

  return (
    <Container maxWidth="lg" className='mt-100'>
      <Grid
        container
        justifyContent="center"
        direction="row-reverse"
        sx={{
          p: 2,
        }}
      >
        <Grid item xs={8} align={'center'} mb={3}>
          <Typography style={{
            "fontFamily":"Muli-SemiBold",
            "fontSize":"18px",
            "textTransform":"uppercase",
            "letterSpacing": "7px",
            "border": "1px solid #dad8e4",
            "display": "inline-block",
            "padding": "0 10px"
          }} variant="h2" color="primary" align="center">
            {event.eventName} <br/>
            ansöknings formulär
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={6}>

          <Formik
            initialValues={{
              name: "",
              email: "",
              event_name: event.eventName || "",
              message: "",}}
            validationSchema={eventSchema}
            onSubmit={(values, actions) => {
              addEvenemang(values);
              actions.resetForm();
              actions.setSubmitting(false);
            }}
            component={(props) => <EventFormData {...props} />}
          ></Formik>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EventForm;
