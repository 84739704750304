import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PlaceIcon from '@mui/icons-material/Place';

const EventCard = ({ event }) => {
  return (
    <Card >
      <CardMedia
        component="img"
        height="140"
        alt="Event Image"
        src={event.eventImage}
      />
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {event.eventName}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
          <EventIcon fontSize="small" /> {event.eventDate.split('T')[0]}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
          <AttachMoneyIcon fontSize="small" /> {event.eventPrice}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
        <PermContactCalendarIcon fontSize='small' /> {event.eventOrganizer}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
        <PlaceIcon fontSize='small' /> {event.eventLocation}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EventCard;
