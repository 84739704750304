import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    evenemangs: [],
    loading:false,
    error:false,
}

const evenemangSlice = createSlice({
  name: 'evenemang',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, {payload}) => {
        state.evenemangs = payload;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
      fetchEnd: (state) => {
        state.loading = false;
      },
  }
});

export const {fetchStart,fetchFail, fetchEnd, loadData} = evenemangSlice.actions

export default evenemangSlice.reducer