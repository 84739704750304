import { useDispatch } from 'react-redux'
import useAxios, { axiosPublic } from './useAxios'
import {fetchStart, loadData, fetchFail} from '../features/kontaktSlice';
import {toastSuccessNotify} from '../helper/ToastNotify'


const useKontaktCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();


    const getKontaktData = async () => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosPublic.get('api/kontakt/');
            dispatch(loadData(data));
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
        }
    }

    const updateContact = async(id, info) => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosWithToken.put(`api/kontakt/${id}/`, info);
            //console.log(data);
            //dispatch(loadData(data));
            getKontaktData();
            toastSuccessNotify(`contact with id_number: ${data?.id} updated successfully.`)
        } catch (error) {
            console.log(error);
        }
    }

  return {getKontaktData, updateContact}
}

export default useKontaktCall