import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import usePostCall from '../hooks/usePostCall';
import {useSelector} from 'react-redux';
import { Helmet } from 'react-helmet';

import MainFeaturedPost from '../components/post/MainFeaturedPost';
import FeaturedPost from '../components/post/FeaturedPost';
import PostCategory from '../components/post/PostCategory';




export default function Blog() {
    
    const {getPosts} = usePostCall();
    const {posts} = useSelector((state) => state.post);

    React.useEffect(() => {
        getPosts();
    }, [])
    

  return (
      <>
      <Helmet>
        <title>Aktiviteter</title>
        <meta name='description' content='This is activities page. ' />
      </Helmet>
      <Container maxWidth="lg" style={{"marginTop":"110px"}}>
        
        <main>
          <MainFeaturedPost post={posts?.[0]} />
          <PostCategory />
          <Grid container spacing={4}>
            {posts?.map((post) => (
              <FeaturedPost key={post.id} post={post} />
            ))}
          </Grid>
          
        </main>
      </Container>
      </>
  );
}