import React, {useState} from "react";
import { flexColumn, modalStyle2 } from "../../styles/globalStyle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Badge, IconButton} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import Modal from "@mui/material/Modal";
import {TextField} from "@mui/material";
//import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import usePartnerCall from "../../hooks/usePartnerCall";
import { Grid } from "@mui/material";

export default function PartnerModal({ open, setOpen, info, setInfo }) {
  const { addPartner, updatePartner } = usePartnerCall();
  const [image, setImage] = useState(null);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    const formData = new FormData();
    formData.append('name', info?.name);
    formData.append('logoImage', image || "");
    if (info.id) {
      updatePartner(info.id, formData);
    } else {
      addPartner(formData);
    }
    setOpen(false);
    setInfo({});
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setInfo({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle2}>
        <Box sx={flexColumn} component={"form"} onSubmit={handleSubmit}>
          
          <Grid container spacing={3}>
            
            <Grid item sx={6}>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            id="name"
            type="text"
            variant="outlined"
            value={info?.name || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            

          <Grid item sx={6} >
            <Badge badgeContent={<IconButton
          color="info"
          aria-label="Info"
          component="span"
          title="Add partner logo image"
        >
          <InfoIcon />
        </IconButton>} sx={{color:"#fff"}}>
                <TextField
            name="image"
            id="image"
            type="file"
            multiple
            accept="image/*"
            variant="outlined"
            // value={info?.image || ""}
            onChange={(e) => {
              setImage(e.target.files[0])
            }}
          />
            </Badge>
              
            </Grid>
            
          </Grid>
          <Button type="submit" variant="contained" size="large">
          {info?.id ? "Update Partner" : "Add New Partner"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
