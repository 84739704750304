import { useEffect, useState } from "react";
import useKontaktCall from "../../hooks/useKontaktCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ContactModal from "../modals/ContactModal";
import ContactTable from "../tables/ContactTable";

const ContactContent = () => {


  const { getKontaktData } = useKontaktCall();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});

  useEffect(() => {
    getKontaktData();
  }, []);

  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Contact Content
      </Typography>

      

      <ContactModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      />

      <ContactTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  )
}

export default ContactContent
