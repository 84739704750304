import React, {useState, useEffect} from "react";
import { btnHoverStyle3, btnHoverStyle2, flexColumn, modalStyle2 } from "../../styles/globalStyle";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InfoIcon from '@mui/icons-material/Info';
import {Badge, IconButton} from '@mui/material';
import {TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
//import { MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import usePostCall from "../../hooks/usePostCall";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from '@mui/material';

export default function PostModal({ open, setOpen, info, setInfo }) {
  const { addPost, updatePost, getCategories, addNewCategory } = usePostCall();
  const {categories} = useSelector((state)=> state.post);
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState({});

  const [innerOpen, setInnerOpen] = useState(false);

  const handleInnerOpen = () => {
    setInnerOpen(true);
  };

  const handleInnerClose = () => {
    setInnerOpen(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    const formData = new FormData();
    formData.append('title', info?.title);
    formData.append('content', info?.content);
    formData.append('category_id', info?.category_id);
    formData.append('post_date', info?.post_date);
    formData.append('is_published', info?.is_published);
    formData.append('image', image || "");
    if (info.id) {
      updatePost(info.id, formData);
    } else {
      addPost(formData);
    }
    setOpen(false);
    setInfo({});
  };

  const categorySubmit = (e) => {
    e.preventDefault();
    addNewCategory(category);
    setTimeout(() => {
      getCategories()
    }, 1000);
  }

  useEffect(() => {
    getCategories();
  }, [])

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setInfo({});
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle2}>
        <Box sx={flexColumn} component={"form"} onSubmit={handleSubmit}>
          
          <Grid container spacing={3}>
            <Grid item sx={6} >
              <TextField
            margin="dense"
            label="Title"
            name="title"
            id="title"
            type="text"
            variant="outlined"
            value={info?.title || ""}
            onChange={handleChange}
            required
          />
            </Grid>
            <Grid item sx={6}>
            <TextField
            margin="dense"
            label="Content"
            name="content"
            id="content"
            type="text"
            multiline
            rows={8}
            variant="outlined"
            value={info?.content || ""}
            onChange={handleChange}
            required
          />
            </Grid>

            <Grid item sx={6}>
      <FormControl fullWidth required>
        <InputLabel margin="dense">Category</InputLabel>
        <Select
        style={{width: "120px"}}
          name="category_id"
          value={info?.category_id || ""}
          onChange={handleChange}
        >
          <MenuItem value="">
          <em>Select a category</em>
        </MenuItem>
        {categories?.map((category) =>{
            return(
          <MenuItem key={category?.id} value={category?.id}>
            {category?.name}
          </MenuItem>)
          })}
        </Select>
        <Button color="success" sx={ btnHoverStyle2} onClick={handleInnerOpen}>
            Add New Category
          </Button>

          <Dialog open={innerOpen} onClose={handleInnerClose}>
            <DialogTitle>Add New Category</DialogTitle>
            <DialogContent>
              <DialogContentText>
              <div className="modal-body">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              value={category.name || ''}
                              onChange={(e) => setCategory({ ...category, name: e.target.value })}
                              className="form-control"
                              id="floatingInput"
                              placeholder="Add new category"
                              required
                            />
                            <label htmlFor="floatingInput">Category</label>
                          </div>
                        </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={btnHoverStyle3} onClick={handleInnerClose} color="primary">
                Cancel
              </Button>
              <Button sx={btnHoverStyle2} onClick={categorySubmit} color="primary">
                Save Category
              </Button>
            </DialogActions>
          </Dialog>
      </FormControl>
      </Grid>

            <Grid item sx={6}>
            <Badge badgeContent={<IconButton
          color="warning"
          aria-label="Info"
          component="span"
          title="Python ve JS arasindaki tarih uyusmazligindan dolayi sorun oluyor ilerleyen zamanda duzeltecegim. lutfen istediginiz tarihten 1 gun sonrasini seciniz!!!"
        >
          <InfoIcon />
        </IconButton>} sx={{color:"red"}}>
            <TextField
          label="Post Date"
          name="post_date"
          type="date"
          value={info?.post_date || ""}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          />
          </Badge>
            </Grid>
            
          <Grid item sx={6}>
      <FormControl fullWidth required>
        <InputLabel>Status</InputLabel>
        <Select
        style={{width: "120px"}}
          name="is_published"
          value={info?.is_published || ""}
          onChange={handleChange}
        >
          <MenuItem value="true">Published</MenuItem>
          <MenuItem value="false">Draft</MenuItem>
        </Select>
      </FormControl>
      </Grid>
            
      <Grid item sx={6} >
            <Badge badgeContent={<IconButton
          color="info"
          aria-label="Info"
          component="span"
          title="Add Post image it must be 800X400 please!!!"
        >
          <InfoIcon />
        </IconButton>} sx={{color:"#fff"}}>
                <TextField
            name="image"
            id="image"
            type="file"
            multiple
            accept="image/*"
            variant="outlined"
            // value={info?.image || ""}
            onChange={(e) => {
              setImage(e.target.files[0])
            }}
          />
            </Badge>
              
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" size="large">
          {info?.id ? "Update Post" : "Add New Post"}
          </Button>
        </Box>
      </Box>
      
    </Modal>
  );
}
