import React, {useState} from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Paper
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";


import { Form } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import TermsAndConditions from '../modals/TermsAndConditions';


export const memberSchema = Yup.object().shape({
    first_name: Yup.string().required("Förnamn är obligatoriskt"),
    last_name: Yup.string().required("Efternamn är obligatoriskt"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("E-post är obligatoriskt."),
  password: Yup.string()
    .min(8, "Password must have min 8 chars")
    .max(16, "Password must have max 16 chars")
    .matches(/\d+/, "Password must have a number")
    .matches(/[a-z]+/, "Password must have a lowercase")
    .matches(/[A-Z]+/, "Password must have an uppercase")
    .matches(/[!,?{}><%&$#£+-.]+/, " Password must have a special char"),
    phone_number: Yup.number().required("Telefonnummer är obligatoriskt"),
    address: Yup.string().required("Adress är obligatoriskt"),
    zip_code: Yup.number().required("Postnummer är obligatoriskt"),
    city: Yup.string().required("Ort är obligatoriskt"),
});

const MembershipForm = ({ values, handleChange, errors, touched, handleBlur }) => {

  const [open, setOpen] = useState(false);
  const { loading } = useSelector((state) => state.member);
  return (
    <Form>
      <Paper elevation={3} style={{ padding: '20px' }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TextField
        label="Förnamn"
        name="first_name"
        value={values.first_name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.first_name && errors.first_name}
        error={touched.first_name && Boolean(errors.first_name)}
        fullWidth
        required
      />
      <TextField
        label="Efternamn"
        name="last_name"
        value={values.last_name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.last_name && errors.last_name}
        error={touched.last_name && Boolean(errors.last_name)}
        fullWidth
        required
      />
      <TextField
        label="E-Post"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email && errors.email}
        error={touched.email && Boolean(errors.email)}
        fullWidth
        required
      />
      <TextField
        label="Födelsedag"
        name="birthday"
        type="date"
        value={values.birthday}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.birthday && errors.birthday}
        error={touched.birthday && Boolean(errors.birthday)}
        fullWidth
        required
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl fullWidth required>
        <InputLabel>Kön</InputLabel>
        <Select
          name="gender"
          value={values.gender}
          onChange={handleChange}
          onBlur={handleBlur}
        // helperText={touched.gender && errors.gender}
        error={touched.gender && Boolean(errors.gender)}
        >
          <MenuItem value="M">Han</MenuItem>
          <MenuItem value="F">Hon</MenuItem>
          <MenuItem value="O">Hen</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Telefonnummer"
        name="phone_number"
        value={values.phone_number}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.phone_number && errors.phone_number}
        error={touched.phone_number && Boolean(errors.phone_number)}
        fullWidth
        required
      />
      <TextField
        label="Adress"
        name="address"
        value={values.address}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.address && errors.address}
        error={touched.address && Boolean(errors.address)}
        fullWidth
        required
      />
      <TextField
        label="Postnummer"
        name="zip_code"
        value={values.zip_code}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.zip_code && errors.zip_code}
        error={touched.zip_code && Boolean(errors.zip_code)}
        fullWidth
        required
      />
      <TextField
        label="Ort"
        name="city"
        value={values.city}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.city && errors.city}
        error={touched.city && Boolean(errors.city)}
        fullWidth
        required
      />
      <FormControlLabel
        control={
          <Checkbox
            name="terms_condition"
            checked={values.terms_condition}
            onChange={handleChange}
            color='primary'
          />
        }
        label={
          <span>
            Vänligen acceptera{' '}
            <Typography
            style={{ textDecoration: 'underline' }}
              onClick={() => setOpen(true)}
              className="terms-conditions d-inline"
              color="primary"
            >
              villkoren.
            </Typography>
          </span>
        }
        required
      />
      <TermsAndConditions open={open} setOpen={setOpen} />

        <LoadingButton
          loading={loading}
          loadingPosition="center"
          variant="contained"
          type="submit"
        >
          Skicka in
        </LoadingButton>
      </Box>
      </Paper>
    </Form>
  );
};

export default MembershipForm;
