import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from "react-router-dom";
import {toastSuccessNotify} from "../helper/ToastNotify";
import useAuthCalls from '../hooks/useAuthCalls';
import {
  Button,
  Container,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material';

const wrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const innerStyle = {
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '800px',
  width: '100%',
};

const imageHolderStyle = {
  flex: 1,
};

const formContentStyle = {
  flex: 1,
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
};

const formHeaderStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};


const formRowStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const formHolderStyle = {
  flex: 1,
  marginRight: '16px',
};

const formButtonsStyle = {
  marginTop: '16px',
};

const Login = () => {

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const {login} = useAuthCalls();
  const {currentUser, loading} = useSelector((state) => state.auth);

  //console.log(currentUser);

  useEffect(() => {
    if (currentUser) {
      navigate("/admin");
      toastSuccessNotify("Log-in is performed successfully");
    }
  }, [navigate, currentUser])


  return (
    <>
    <Helmet>
      <title>Login</title>
      <meta name='description' content='This is the login page' />
    </Helmet>
    <Container style={wrapperStyle}>
      <div style={innerStyle}>
        <div style={imageHolderStyle}>
          <img
            src="https://picsum.photos/368/521?random=1"
            alt=""
          />
        </div>
        <div style={formContentStyle}>
          <div style={formHeaderStyle}>
            <Typography variant="h4" mb={3}>LOGIN</Typography>
          </div>
          <div style={formRowStyle}>
            <div style={formHolderStyle}>
              <TextField
                fullWidth
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                variant="outlined"
                label="Your Email"
              />
            </div>
            <div style={formHolderStyle}>
              <TextField
                fullWidth
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                variant="outlined"
                type="password"
                label="Password"
              />
            </div>
          </div>
          <div style={formButtonsStyle}>
            <Button
              onClick={() => login(formData)}
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={25} /> : 'Submit'}
            </Button>
          </div>
          <Typography style={formButtonsStyle}>
            Do not have an account? Click <Link to="/register">here</Link>
          </Typography>
        </div>
      </div>
    </Container>
    </>
  );
};

export default Login;
