import React from 'react'

const LogoPng = (props) =>(
  <svg  width={140}
  height={80} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 458.00 181.00"  {...props}>
  <g fill="none">
  <path stroke="#661d2f" d="
    M 170.95 17.79
    C 117.59 5.37 62.20 14.88 20.52 51.71
    Q 20.26 51.94 19.92 51.67"
  />
  <path stroke="#661d2f" d="
    M 170.73 54.84
    C 164.28 55.48 157.12 53.38 150.81 51.16
    C 134.67 45.47 118.69 39.30 101.68 36.71
    Q 65.33 31.20 32.81 47.81
    Q 18.97 54.88 4.85 67.98"
  />
  <path stroke="#838d9b" d="
    M 142.44 90.07
    L 142.48 84.76
    A 2.32 2.32 0.0 0 1 144.74 82.46
    L 148.33 82.36
    Q 149.25 82.34 149.25 81.42
    L 149.25 76.50
    Q 149.25 75.61 148.37 75.53
    C 146.00 75.30 143.05 74.93 140.77 75.94
    C 134.22 78.84 135.38 85.31 135.28 91.21
    Q 135.27 91.74 134.74 91.72
    C 133.20 91.66 130.74 91.34 129.55 92.38
    C 127.75 93.95 128.04 97.30 130.12 98.34
    C 131.36 98.96 133.28 98.71 134.79 98.73
    Q 135.30 98.74 135.30 99.25
    L 135.29 133.47
    A 0.43 0.43 0.0 0 0 135.72 133.90
    L 142.00 133.94
    A 0.45 0.44 -0.0 0 0 142.45 133.50
    L 142.45 99.26
    Q 142.45 98.73 142.99 98.73
    L 150.00 98.72
    Q 150.23 98.72 150.23 98.50
    L 150.25 91.97
    A 0.28 0.28 0.0 0 0 149.97 91.69
    L 144.06 91.70
    Q 142.43 91.70 142.44 90.07"
  />
  <path stroke="#838d9b" d="
    M 54.51 95.38
    Q 54.11 95.78 53.70 95.41
    Q 46.26 88.79 37.92 90.60
    C 30.52 92.20 25.05 97.94 25.10 105.76
    Q 25.15 112.02 25.08 133.40
    Q 25.08 133.87 25.55 133.87
    L 32.00 133.95
    Q 32.50 133.96 32.50 133.45
    Q 32.52 119.69 32.49 105.50
    C 32.47 93.52 50.22 92.78 50.22 106.76
    Q 50.22 120.25 50.22 133.41
    Q 50.22 133.90 50.72 133.90
    L 57.06 133.90
    A 0.52 0.52 0.0 0 0 57.58 133.38
    Q 57.57 119.39 57.80 105.26
    C 58.00 93.07 75.26 93.91 75.25 105.26
    Q 75.23 119.49 75.24 133.44
    Q 75.24 133.90 75.71 133.90
    L 82.29 133.91
    A 0.45 0.45 0.0 0 0 82.74 133.46
    Q 82.75 120.87 82.73 108.01
    C 82.73 102.10 81.42 96.67 76.70 93.56
    Q 64.34 85.41 54.51 95.38"
  />
  <path stroke="#838d9b" d="
    M 112.08 107.44
    C 106.43 108.37 98.72 108.67 94.39 110.75
    C 85.60 114.98 85.15 127.80 93.70 132.64
    Q 96.27 134.09 100.56 134.47
    C 113.09 135.56 122.38 129.39 122.61 116.25
    Q 122.72 110.03 122.69 103.78
    Q 122.68 98.94 121.75 96.87
    C 118.00 88.51 103.04 89.36 95.92 91.89
    Q 95.68 91.97 95.68 92.23
    L 95.68 97.93
    A 0.19 0.19 0.0 0 0 95.93 98.11
    C 101.10 96.56 115.53 93.07 115.74 102.73
    C 115.80 105.63 115.11 106.95 112.08 107.44"
  />
  <path stroke="#838d9b" d="
    M 109.84 126.62
    C 115.83 123.61 115.50 119.00 115.61 113.06
    Q 115.62 112.47 115.04 112.54
    C 114.18 112.64 113.15 113.27 112.44 113.38
    Q 106.35 114.35 100.24 115.24
    C 97.10 115.70 94.91 118.82 95.12 122.01
    C 95.63 129.71 104.70 129.22 109.84 126.62"
  />
  </g>
  <path fill="#061a37" d="
    M 139.77 0.00
    L 152.85 0.00
    L 170.17 1.75
    A 0.66 0.66 0.0 0 1 170.77 2.40
    L 170.95 17.79
    C 117.59 5.37 62.20 14.88 20.52 51.71
    Q 20.26 51.94 19.92 51.67
    Q 69.95 3.52 139.77 0.00
    Z"
  />
  <path fill="#c62026" d="
    M 170.95 17.79
    L 184.07 21.41
    Q 184.56 21.54 184.57 22.05
    C 184.77 32.07 184.50 53.10 170.73 54.84
    C 164.28 55.48 157.12 53.38 150.81 51.16
    C 134.67 45.47 118.69 39.30 101.68 36.71
    Q 65.33 31.20 32.81 47.81
    Q 18.97 54.88 4.85 67.98
    L 19.92 51.67
    Q 20.26 51.94 20.52 51.71
    C 62.20 14.88 117.59 5.37 170.95 17.79
    Z"
  />
  <path fill="#061a37" d="
    M 243.10 40.49
    Q 242.71 39.37 242.51 40.54
    L 238.79 63.26
    Q 238.73 63.63 238.37 63.63
    L 235.20 63.61
    Q 234.80 63.61 234.87 63.22
    L 240.25 33.23
    A 0.90 0.87 74.4 0 1 240.54 32.70
    C 242.69 30.76 244.33 32.04 245.12 34.35
    Q 247.59 41.63 249.79 48.50
    Q 250.16 49.65 250.54 48.50
    Q 252.90 41.20 255.34 34.11
    C 256.06 32.00 257.76 30.92 259.60 32.54
    Q 260.02 32.90 260.12 33.45
    L 265.38 63.39
    A 0.22 0.22 0.0 0 1 265.15 63.65
    L 261.96 63.59
    Q 261.59 63.58 261.53 63.21
    L 257.77 40.45
    A 0.27 0.27 0.0 0 0 257.25 40.41
    L 252.34 54.33
    Q 252.19 54.76 251.73 54.76
    L 248.70 54.76
    Q 248.11 54.76 247.92 54.20
    L 243.10 40.49
    Z"
  />
  <path fill="#061a37" d="
    M 290.00 63.63
    A 0.44 0.44 0.0 0 1 289.56 63.19
    L 289.56 34.02
    A 2.07 1.96 -90.0 0 1 291.52 31.95
    L 291.58 31.95
    A 2.07 1.96 -90.0 0 1 293.54 34.02
    L 293.54 63.19
    A 0.44 0.44 0.0 0 1 293.10 63.63
    L 290.00 63.63
    Z"
  />
  <circle fill="#061a37" cx="339.00" cy="34.26" r="2.25"/>
  <circle fill="#061a37" cx="345.71" cy="34.27" r="2.24"/>
  <path fill="#061a37" d="
    M 170.73 54.84
    Q 170.37 80.23 165.00 106.74
    C 158.18 140.40 140.43 172.38 103.78 179.52
    Q 101.26 180.01 91.64 181.00
    L 81.25 181.00
    Q 77.13 180.51 73.01 180.14
    C 31.22 176.35 4.71 148.41 0.87 106.74
    Q 0.47 102.36 0.00 97.97
    L 0.00 86.08
    Q -0.27 72.55 4.85 67.98
    Q 18.97 54.88 32.81 47.81
    Q 65.33 31.20 101.68 36.71
    C 118.69 39.30 134.67 45.47 150.81 51.16
    C 157.12 53.38 164.28 55.48 170.73 54.84
    Z
    M 142.44 90.07
    L 142.48 84.76
    A 2.32 2.32 0.0 0 1 144.74 82.46
    L 148.33 82.36
    Q 149.25 82.34 149.25 81.42
    L 149.25 76.50
    Q 149.25 75.61 148.37 75.53
    C 146.00 75.30 143.05 74.93 140.77 75.94
    C 134.22 78.84 135.38 85.31 135.28 91.21
    Q 135.27 91.74 134.74 91.72
    C 133.20 91.66 130.74 91.34 129.55 92.38
    C 127.75 93.95 128.04 97.30 130.12 98.34
    C 131.36 98.96 133.28 98.71 134.79 98.73
    Q 135.30 98.74 135.30 99.25
    L 135.29 133.47
    A 0.43 0.43 0.0 0 0 135.72 133.90
    L 142.00 133.94
    A 0.45 0.44 -0.0 0 0 142.45 133.50
    L 142.45 99.26
    Q 142.45 98.73 142.99 98.73
    L 150.00 98.72
    Q 150.23 98.72 150.23 98.50
    L 150.25 91.97
    A 0.28 0.28 0.0 0 0 149.97 91.69
    L 144.06 91.70
    Q 142.43 91.70 142.44 90.07
    Z
    M 54.51 95.38
    Q 54.11 95.78 53.70 95.41
    Q 46.26 88.79 37.92 90.60
    C 30.52 92.20 25.05 97.94 25.10 105.76
    Q 25.15 112.02 25.08 133.40
    Q 25.08 133.87 25.55 133.87
    L 32.00 133.95
    Q 32.50 133.96 32.50 133.45
    Q 32.52 119.69 32.49 105.50
    C 32.47 93.52 50.22 92.78 50.22 106.76
    Q 50.22 120.25 50.22 133.41
    Q 50.22 133.90 50.72 133.90
    L 57.06 133.90
    A 0.52 0.52 0.0 0 0 57.58 133.38
    Q 57.57 119.39 57.80 105.26
    C 58.00 93.07 75.26 93.91 75.25 105.26
    Q 75.23 119.49 75.24 133.44
    Q 75.24 133.90 75.71 133.90
    L 82.29 133.91
    A 0.45 0.45 0.0 0 0 82.74 133.46
    Q 82.75 120.87 82.73 108.01
    C 82.73 102.10 81.42 96.67 76.70 93.56
    Q 64.34 85.41 54.51 95.38
    Z
    M 112.08 107.44
    C 106.43 108.37 98.72 108.67 94.39 110.75
    C 85.60 114.98 85.15 127.80 93.70 132.64
    Q 96.27 134.09 100.56 134.47
    C 113.09 135.56 122.38 129.39 122.61 116.25
    Q 122.72 110.03 122.69 103.78
    Q 122.68 98.94 121.75 96.87
    C 118.00 88.51 103.04 89.36 95.92 91.89
    Q 95.68 91.97 95.68 92.23
    L 95.68 97.93
    A 0.19 0.19 0.0 0 0 95.93 98.11
    C 101.10 96.56 115.53 93.07 115.74 102.73
    C 115.80 105.63 115.11 106.95 112.08 107.44
    Z"
  />
  <path fill="#061a37" d="
    M 281.98 48.18
    Q 282.68 46.51 281.79 45.15
    C 279.80 42.10 274.45 43.41 271.75 44.30
    A 0.34 0.34 0.0 0 1 271.30 43.98
    L 271.32 41.43
    Q 271.32 41.00 271.73 40.86
    C 276.21 39.35 285.99 39.30 285.98 45.98
    C 285.98 54.07 287.36 63.37 276.00 63.93
    C 271.71 64.14 266.67 62.34 266.93 57.24
    C 267.31 49.81 274.95 49.96 280.30 49.42
    A 2.04 2.04 0.0 0 0 281.98 48.18
    Z
    M 270.93 57.00
    C 271.32 63.45 283.71 61.06 282.03 52.55
    Q 281.94 52.11 281.50 52.22
    C 278.65 52.96 270.67 52.88 270.93 57.00
    Z"
  />
  <path fill="#061a37" d="
    M 313.19 42.67
    C 319.27 36.80 328.19 40.85 328.27 49.28
    Q 328.33 56.35 328.29 63.16
    A 0.49 0.49 0.0 0 1 327.80 63.64
    L 324.60 63.61
    Q 324.23 63.60 324.23 63.23
    L 324.26 47.82
    A 4.41 4.41 0.0 0 0 319.57 43.42
    L 318.12 43.52
    A 3.62 3.61 88.1 0 0 314.74 47.13
    L 314.74 63.25
    Q 314.74 63.61 314.37 63.61
    L 311.09 63.63
    A 0.38 0.38 0.0 0 1 310.71 63.25
    L 310.72 48.24
    A 4.65 4.64 0.4 0 0 306.13 43.59
    L 304.81 43.58
    A 3.52 3.52 0.0 0 0 301.25 47.10
    L 301.24 63.29
    Q 301.24 63.64 300.89 63.63
    L 297.63 63.60
    Q 297.20 63.60 297.20 63.16
    C 297.21 58.81 296.11 46.87 298.69 43.59
    C 302.30 38.99 308.07 38.85 312.39 42.69
    Q 312.80 43.05 313.19 42.67
    Z"
  />
  <path fill="#061a37" d="
    M 342.3391 63.9300
    A 11.99 11.00 90.1 0 1 331.3600 51.9208
    A 11.99 11.00 90.1 0 1 342.3809 39.9500
    A 11.99 11.00 90.1 0 1 353.3600 51.9592
    A 11.99 11.00 90.1 0 1 342.3391 63.9300
    Z
    M 342.3646 60.2800
    A 8.34 7.06 89.9 0 0 349.4100 51.9277
    A 8.34 7.06 89.9 0 0 342.3354 43.6000
    A 8.34 7.06 89.9 0 0 335.2900 51.9523
    A 8.34 7.06 89.9 0 0 342.3646 60.2800
    Z"
  />
  <path fill="#061a37" d="
    M 359.83 45.81
    Q 359.09 48.19 361.76 48.94
    C 365.28 49.91 371.43 50.32 372.88 54.45
    C 376.54 64.93 357.71 67.90 355.39 57.56
    Q 355.23 56.85 355.95 56.85
    L 358.52 56.86
    Q 359.17 56.86 359.33 57.50
    C 360.33 61.47 368.63 61.56 369.42 57.61
    C 370.16 53.92 363.07 53.28 360.75 52.75
    C 356.21 51.69 354.67 46.91 357.15 43.19
    C 360.63 38.00 371.20 39.04 372.49 45.66
    Q 372.61 46.25 372.01 46.25
    L 369.27 46.25
    Q 368.79 46.25 368.55 45.83
    C 366.84 42.86 360.94 42.28 359.83 45.81
    Z"
  />
  <path fill="#ffffff" d="
    M 144.06 91.70
    L 149.97 91.69
    A 0.28 0.28 0.0 0 1 150.25 91.97
    L 150.23 98.50
    Q 150.23 98.72 150.00 98.72
    L 142.99 98.73
    Q 142.45 98.73 142.45 99.26
    L 142.45 133.50
    A 0.45 0.44 -0.0 0 1 142.00 133.94
    L 135.72 133.90
    A 0.43 0.43 0.0 0 1 135.29 133.47
    L 135.30 99.25
    Q 135.30 98.74 134.79 98.73
    C 133.28 98.71 131.36 98.96 130.12 98.34
    C 128.04 97.30 127.75 93.95 129.55 92.38
    C 130.74 91.34 133.20 91.66 134.74 91.72
    Q 135.27 91.74 135.28 91.21
    C 135.38 85.31 134.22 78.84 140.77 75.94
    C 143.05 74.93 146.00 75.30 148.37 75.53
    Q 149.25 75.61 149.25 76.50
    L 149.25 81.42
    Q 149.25 82.34 148.33 82.36
    L 144.74 82.46
    A 2.32 2.32 0.0 0 0 142.48 84.76
    L 142.44 90.07
    Q 142.43 91.70 144.06 91.70
    Z"
  />
  <path fill="#061a37" d="
    M 254.64 101.33
    L 242.57 101.35
    A 0.39 0.39 0.0 0 0 242.20 101.61
    L 239.02 110.53
    Q 238.95 110.74 238.72 110.75
    L 235.06 110.82
    Q 234.79 110.83 234.89 110.58
    Q 240.61 95.46 246.26 80.48
    C 247.06 78.37 251.00 78.60 251.89 81.02
    Q 257.23 95.63 262.59 110.34
    A 0.39 0.38 80.7 0 1 262.22 110.86
    L 258.96 110.75
    A 0.94 0.93 81.3 0 1 258.11 110.12
    L 255.22 101.74
    Q 255.07 101.33 254.64 101.33
    Z
    M 243.63 97.73
    A 0.33 0.33 0.0 0 0 243.94 98.17
    L 253.51 98.22
    A 0.33 0.33 0.0 0 0 253.82 97.79
    L 249.11 84.29
    A 0.33 0.33 0.0 0 0 248.49 84.28
    L 243.63 97.73
    Z"
  />
  <path fill="#061a37" d="
    M 269.16 96.74
    Q 269.16 97.46 269.68 96.95
    L 278.50 88.26
    Q 278.71 88.05 279.02 88.04
    L 283.45 87.95
    A 0.19 0.19 0.0 0 1 283.59 88.28
    L 275.44 96.31
    A 0.58 0.56 51.3 0 0 275.37 97.02
    L 284.31 110.42
    A 0.32 0.32 0.0 0 1 284.04 110.92
    L 280.20 110.85
    Q 279.52 110.83 279.16 110.25
    L 273.46 101.07
    A 1.76 1.76 0.0 0 0 271.38 100.34
    C 268.15 101.49 269.17 107.66 269.24 110.30
    Q 269.25 110.79 268.77 110.78
    L 265.82 110.78
    A 0.46 0.46 0.0 0 1 265.36 110.32
    L 265.37 81.10
    Q 265.37 79.07 267.40 79.24
    L 267.82 79.27
    A 1.55 1.54 -87.4 0 1 269.23 80.82
    L 269.16 96.74
    Z"
  />
  <path fill="#061a37" d="
    M 422.16 96.73
    Q 422.16 97.47 422.69 96.95
    L 431.58 88.25
    Q 431.78 88.05 432.07 88.04
    L 436.45 87.95
    A 0.19 0.19 0.0 0 1 436.59 88.28
    L 428.51 96.27
    A 0.62 0.62 0.0 0 0 428.43 97.05
    L 437.52 110.71
    A 0.14 0.14 0.0 0 1 437.40 110.93
    L 432.78 110.84
    A 0.41 0.40 73.5 0 1 432.44 110.65
    L 426.57 101.20
    A 2.00 2.00 0.0 0 0 423.78 100.59
    C 421.37 102.19 422.18 108.00 422.23 110.31
    Q 422.24 110.79 421.76 110.79
    L 418.81 110.78
    Q 418.35 110.78 418.35 110.31
    L 418.35 81.12
    Q 418.35 79.08 420.38 79.23
    L 420.80 79.26
    A 1.54 1.53 2.4 0 1 422.22 80.80
    L 422.16 96.73
    Z"
  />
  <path fill="#061a37" d="
    M 324.37 89.74
    L 324.29 79.98
    Q 324.29 79.50 324.77 79.50
    L 327.73 79.49
    Q 328.13 79.49 328.13 79.89
    Q 328.09 89.78 328.15 99.88
    C 328.19 106.24 323.67 111.53 317.01 111.15
    C 306.80 110.57 304.73 91.94 313.46 88.10
    Q 319.15 85.60 324.00 89.91
    Q 324.37 90.24 324.37 89.74
    Z
    M 317.9646 107.6889
    A 8.62 6.25 90.9 0 0 324.3492 99.1682
    A 8.62 6.25 90.9 0 0 318.2354 90.4511
    A 8.62 6.25 90.9 0 0 311.8508 98.9718
    A 8.62 6.25 90.9 0 0 317.9646 107.6889
    Z"
  />
  <circle fill="#061a37" cx="392.83" cy="81.65" r="2.24"/>
  <path fill="#061a37" d="
    M 301.20 93.24
    C 300.18 89.17 293.41 90.43 290.67 91.66
    A 0.21 0.21 0.0 0 1 290.38 91.47
    L 290.41 88.46
    Q 290.41 88.11 290.74 88.01
    C 294.47 86.90 301.05 86.38 303.82 89.98
    Q 305.05 91.59 305.03 95.22
    C 304.99 101.85 305.93 108.64 297.76 110.85
    C 285.70 114.12 281.43 99.74 291.75 97.66
    Q 293.46 97.32 298.58 96.70
    C 300.70 96.44 301.75 95.45 301.20 93.24
    Z
    M 293.72 107.76
    C 298.78 108.05 301.86 104.77 301.15 99.80
    Q 301.09 99.37 300.66 99.47
    C 298.19 100.02 293.29 100.15 291.47 101.47
    C 288.59 103.55 290.24 107.55 293.72 107.76
    Z"
  />
  <path fill="#061a37" d="
    M 335.37 101.68
    C 336.63 107.32 342.80 109.53 347.06 105.06
    A 0.34 0.34 0.0 0 1 347.50 105.02
    L 350.11 106.91
    Q 350.33 107.07 350.16 107.27
    C 345.37 112.91 337.25 112.35 333.26 106.04
    Q 329.38 99.88 332.62 93.31
    C 338.54 81.33 354.35 88.37 353.00 100.50
    A 0.77 0.76 -86.6 0 1 352.24 101.18
    L 335.78 101.17
    Q 335.26 101.17 335.37 101.68
    Z
    M 335.53 97.24
    L 348.63 97.24
    A 0.18 0.18 0.0 0 0 348.81 97.06
    L 348.81 97.01
    A 6.65 6.17 0.0 0 0 342.16 90.84
    L 342.00 90.84
    A 6.65 6.17 -0.0 0 0 335.35 97.01
    L 335.35 97.06
    A 0.18 0.18 0.0 0 0 335.53 97.24
    Z"
  />
  <path fill="#061a37" d="
    M 371.05 89.85
    Q 371.44 90.28 371.88 89.90
    Q 377.15 85.37 383.01 88.43
    C 386.66 90.33 387.26 93.74 387.25 97.92
    Q 387.24 104.32 387.25 110.49
    Q 387.26 110.80 386.95 110.79
    L 383.47 110.77
    Q 383.18 110.77 383.18 110.47
    L 383.20 95.30
    A 4.53 4.53 0.0 0 0 378.64 90.76
    L 377.19 90.77
    A 3.55 3.55 0.0 0 0 373.66 94.32
    L 373.66 110.31
    Q 373.66 110.78 373.19 110.78
    L 370.07 110.78
    Q 369.61 110.79 369.61 110.32
    L 369.63 95.37
    A 4.60 4.59 0.4 0 0 365.09 90.77
    L 363.77 90.75
    A 3.55 3.55 0.0 0 0 360.18 94.30
    L 360.18 110.43
    Q 360.18 110.80 359.82 110.79
    L 356.47 110.75
    Q 356.08 110.75 356.08 110.35
    Q 356.15 103.97 356.14 97.40
    Q 356.14 93.28 357.27 91.30
    C 360.03 86.48 367.38 85.79 371.05 89.85
    Z"
  />
  <path fill="#061a37" d="
    M 409.01 107.26
    C 412.85 106.04 412.13 102.16 408.50 101.31
    Q 405.35 100.58 402.26 99.71
    C 396.09 97.98 397.31 89.89 402.30 87.82
    C 406.91 85.90 413.03 87.55 414.66 92.83
    A 0.47 0.47 0.0 0 1 414.22 93.44
    L 411.54 93.47
    Q 411.05 93.47 410.76 93.07
    Q 408.33 89.67 404.32 90.91
    C 402.66 91.43 401.32 92.85 401.98 94.64
    A 2.44 2.43 86.7 0 0 403.67 96.15
    Q 406.93 96.98 410.01 97.63
    C 416.95 99.09 417.21 107.85 410.70 110.37
    C 405.72 112.30 398.51 110.88 397.51 104.66
    Q 397.42 104.09 397.99 104.10
    L 400.31 104.11
    Q 400.86 104.11 401.15 104.60
    Q 403.68 108.96 409.01 107.26
    Z"
  />
  <path fill="#061a37" d="
    M 458.00 92.02
    L 458.00 102.76
    Q 457.19 107.59 454.63 109.14
    C 450.29 111.78 441.15 112.75 439.36 106.62
    C 436.85 98.02 445.93 97.39 451.64 96.69
    C 453.62 96.45 454.70 95.58 454.28 93.48
    C 453.39 89.08 446.42 90.45 443.70 91.63
    A 0.20 0.19 -11.5 0 1 443.42 91.45
    L 443.48 88.31
    Q 443.48 88.09 443.69 88.03
    C 448.42 86.61 455.90 86.29 458.00 92.02
    Z
    M 454.35 101.28
    Q 454.91 98.94 452.60 99.62
    C 450.27 100.30 446.03 100.15 444.24 101.66
    C 442.52 103.10 442.76 106.06 444.71 107.20
    Q 446.98 108.52 450.94 106.97
    Q 453.19 106.10 453.75 103.76
    L 454.35 101.28
    Z"
  />
  <rect fill="#061a37" x="390.80" y="88.00" width="3.98" height="22.78" rx="0.35"/>
  <path fill="#ffffff" d="
    M 54.51 95.38
    Q 64.34 85.41 76.70 93.56
    C 81.42 96.67 82.73 102.10 82.73 108.01
    Q 82.75 120.87 82.74 133.46
    A 0.45 0.45 0.0 0 1 82.29 133.91
    L 75.71 133.90
    Q 75.24 133.90 75.24 133.44
    Q 75.23 119.49 75.25 105.26
    C 75.26 93.91 58.00 93.07 57.80 105.26
    Q 57.57 119.39 57.58 133.38
    A 0.52 0.52 0.0 0 1 57.06 133.90
    L 50.72 133.90
    Q 50.22 133.90 50.22 133.41
    Q 50.22 120.25 50.22 106.76
    C 50.22 92.78 32.47 93.52 32.49 105.50
    Q 32.52 119.69 32.50 133.45
    Q 32.50 133.96 32.00 133.95
    L 25.55 133.87
    Q 25.08 133.87 25.08 133.40
    Q 25.15 112.02 25.10 105.76
    C 25.05 97.94 30.52 92.20 37.92 90.60
    Q 46.26 88.79 53.70 95.41
    Q 54.11 95.78 54.51 95.38
    Z"
  />
  <path fill="#ffffff" d="
    M 112.08 107.44
    C 115.11 106.95 115.80 105.63 115.74 102.73
    C 115.53 93.07 101.10 96.56 95.93 98.11
    A 0.19 0.19 0.0 0 1 95.68 97.93
    L 95.68 92.23
    Q 95.68 91.97 95.92 91.89
    C 103.04 89.36 118.00 88.51 121.75 96.87
    Q 122.68 98.94 122.69 103.78
    Q 122.72 110.03 122.61 116.25
    C 122.38 129.39 113.09 135.56 100.56 134.47
    Q 96.27 134.09 93.70 132.64
    C 85.15 127.80 85.60 114.98 94.39 110.75
    C 98.72 108.67 106.43 108.37 112.08 107.44
    Z
    M 109.84 126.62
    C 115.83 123.61 115.50 119.00 115.61 113.06
    Q 115.62 112.47 115.04 112.54
    C 114.18 112.64 113.15 113.27 112.44 113.38
    Q 106.35 114.35 100.24 115.24
    C 97.10 115.70 94.91 118.82 95.12 122.01
    C 95.63 129.71 104.70 129.22 109.84 126.62
    Z"
  />
  <path fill="#061a37" d="
    M 109.84 126.62
    C 104.70 129.22 95.63 129.71 95.12 122.01
    C 94.91 118.82 97.10 115.70 100.24 115.24
    Q 106.35 114.35 112.44 113.38
    C 113.15 113.27 114.18 112.64 115.04 112.54
    Q 115.62 112.47 115.61 113.06
    C 115.50 119.00 115.83 123.61 109.84 126.62
    Z"
  />
  <path fill="#061a37" d="
    M 240.09 140.22
    L 251.98 140.22
    A 0.31 0.31 0.0 0 1 252.29 140.53
    L 252.30 143.30
    A 0.31 0.31 0.0 0 1 251.99 143.61
    L 240.08 143.59
    A 0.31 0.31 0.0 0 0 239.77 143.90
    L 239.78 157.73
    A 0.31 0.31 0.0 0 1 239.47 158.04
    L 236.11 158.04
    A 0.31 0.31 0.0 0 1 235.80 157.73
    L 235.80 126.93
    A 0.31 0.31 0.0 0 1 236.11 126.62
    L 254.17 126.62
    A 0.31 0.31 0.0 0 1 254.48 126.93
    L 254.45 129.91
    A 0.31 0.31 0.0 0 1 254.14 130.22
    L 240.08 130.22
    A 0.31 0.31 0.0 0 0 239.77 130.53
    L 239.78 139.91
    A 0.31 0.31 0.0 0 0 240.09 140.22
    Z"
  />
  <circle fill="#061a37" cx="264.08" cy="128.66" r="2.24"/>
  <circle fill="#061a37" cx="270.78" cy="128.66" r="2.24"/>
  <circle fill="#061a37" cx="342.25" cy="128.84" r="2.25"/>
  <path fill="#061a37" d="
    M 267.4609 158.3400
    A 11.99 11.01 89.9 0 1 256.4300 146.3692
    A 11.99 11.01 89.9 0 1 267.4191 134.3600
    A 11.99 11.01 89.9 0 1 278.4500 146.3308
    A 11.99 11.01 89.9 0 1 267.4609 158.3400
    Z
    M 267.4009 154.6899
    A 8.34 7.06 90.2 0 0 274.4900 146.3746
    A 8.34 7.06 90.2 0 0 267.4591 138.0101
    A 8.34 7.06 90.2 0 0 260.3700 146.3254
    A 8.34 7.06 90.2 0 0 267.4009 154.6899
    Z"
  />
  <path fill="#061a37" d="
    M 290.73 138.33
    C 286.90 138.05 285.39 141.06 285.39 144.37
    Q 285.40 151.06 285.39 157.61
    Q 285.39 158.04 284.96 158.04
    L 281.96 158.04
    A 0.48 0.48 0.0 0 1 281.47 157.55
    Q 281.53 151.76 281.48 145.76
    C 281.41 138.86 283.44 134.60 290.83 134.38
    Q 291.63 134.36 291.62 135.16
    L 291.60 137.53
    Q 291.59 138.40 290.73 138.33
    Z"
  />
  <path fill="#061a37" d="
    M 297.64 148.81
    C 299.68 154.75 304.87 156.61 309.63 152.31
    Q 309.85 152.10 310.09 152.29
    L 312.61 154.20
    Q 312.95 154.46 312.62 154.74
    Q 305.94 160.52 299.77 157.15
    C 294.99 154.53 292.53 148.83 293.81 143.51
    C 297.36 128.88 316.68 133.08 315.46 147.85
    Q 315.42 148.31 314.96 148.31
    L 298.00 148.31
    A 0.38 0.38 0.0 0 0 297.64 148.81
    Z
    M 297.98 144.41
    L 311.14 144.45
    A 0.18 0.18 0.0 0 0 311.32 144.27
    L 311.32 144.23
    A 6.65 6.14 0.2 0 0 304.69 138.07
    L 304.47 138.07
    A 6.65 6.14 0.2 0 0 297.80 144.19
    L 297.80 144.23
    A 0.18 0.18 0.0 0 0 297.98 144.41
    Z"
  />
  <path fill="#061a37" d="
    M 332.31 141.75
    C 332.30 137.94 326.08 136.40 323.82 139.63
    C 321.77 142.55 322.75 153.88 322.76 157.72
    Q 322.76 158.04 322.44 158.04
    L 319.19 158.04
    Q 318.76 158.04 318.76 157.62
    Q 318.73 151.05 318.76 144.30
    Q 318.77 140.62 320.05 138.54
    C 323.89 132.32 333.41 133.14 335.93 140.10
    Q 336.45 141.56 336.45 144.44
    Q 336.43 151.16 336.44 157.58
    Q 336.44 158.04 335.99 158.04
    L 332.77 158.05
    Q 332.31 158.05 332.32 157.59
    Q 332.34 149.83 332.31 141.75
    Z"
  />
  <path fill="#061a37" d="
    M 355.80 137.90
    A 3.43 3.43 0.0 0 0 352.24 141.33
    L 352.22 157.53
    Q 352.22 158.04 351.72 158.04
    L 348.54 158.04
    A 0.44 0.44 0.0 0 1 348.10 157.59
    Q 348.18 151.22 348.15 144.71
    Q 348.12 140.72 349.37 138.52
    C 352.39 133.22 361.25 132.83 364.50 138.34
    Q 365.71 140.41 365.74 144.47
    Q 365.78 151.15 365.73 157.62
    Q 365.73 158.05 365.30 158.04
    L 361.99 158.04
    Q 361.70 158.04 361.70 157.74
    L 361.70 142.71
    A 4.78 4.77 1.1 0 0 357.10 137.94
    L 355.80 137.90
    Z"
  />
  <path fill="#061a37" d="
    M 384.78 155.59
    C 380.00 160.45 371.90 158.56 369.81 152.25
    C 367.85 146.28 369.17 136.72 376.48 134.65
    Q 380.09 133.62 383.51 135.47
    C 390.49 139.23 388.96 147.46 389.05 154.08
    C 389.14 161.03 387.78 165.84 379.52 166.09
    A 0.28 0.27 -1.0 0 1 379.23 165.82
    L 379.19 163.23
    Q 379.19 162.89 379.53 162.87
    Q 386.18 162.47 385.27 155.76
    Q 385.19 155.18 384.78 155.59
    Z
    M 379.1751 155.0494
    A 8.60 6.24 89.3 0 0 385.3095 146.3738
    A 8.60 6.24 89.3 0 0 378.9649 137.8506
    A 8.60 6.24 89.3 0 0 372.8305 146.5262
    A 8.60 6.24 89.3 0 0 379.1751 155.0494
    Z"
  />
  <rect fill="#061a37" x="340.27" y="135.25" width="3.98" height="22.80" rx="0.36"/>
  </svg>
)

export default LogoPng