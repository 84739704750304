import { useDispatch } from "react-redux"
import {fetchStart,fetchFail,loadData} from '../features/footerSlice';
import useAxios, { axiosPublic } from "./useAxios";
import {toastSuccessNotify} from '../helper/ToastNotify';



const useFooterCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();

    const getFooter = async()=>{
        dispatch(fetchStart())
        try {
            const {data} = await axiosPublic.get('api/footer/')
            dispatch(loadData(data))
        } catch (error) {
            dispatch(fetchFail())
            console.log(error)
        }
    }

    const updateFooter = async(info) => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosWithToken.put(`api/footer/${info.id}/`, info);
            toastSuccessNotify(`${data?.infoMail} updated successfully.`)
            getFooter();
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
        }
    }


  return {getFooter, updateFooter}
}

export default useFooterCall