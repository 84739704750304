import useAxios, {axiosPublic} from '../hooks/useAxios';
import { useDispatch } from 'react-redux'
import {fetchFail,fetchStart,loadData} from '../features/aboutSlice';
import {toastSuccessNotify} from '../helper/ToastNotify'
const useAboutCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();
    

    const getAbout = async() => {
        dispatch(fetchStart())
        try {
            const {data} = await axiosPublic.get('api/about/');
            dispatch(loadData(data))
        } catch (error) {
            dispatch(fetchFail)
            console.log(error)
        }
    }

    const updateAbout = async(info) => {
        dispatch(fetchStart());
        try {
            const {data} = await axiosWithToken.put(`api/about/${info.id}/`, info);
            dispatch(loadData(data));
            toastSuccessNotify(`${data?.visionTitle} updated successfully.`)
            getAbout();
        } catch (error) {
            dispatch(fetchFail());
            console.log(error);
        }
    }

  return {getAbout, updateAbout}
}

export default useAboutCall