import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import KpiCards from "./KpiCards";

const AdminHome = () => {
  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Dashboard
      </Typography>
      <KpiCards />
    </Box>
  )
}

export default AdminHome