import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import LabelIcon from '@mui/icons-material/Label';
import usePostCall from '../../hooks/usePostCall';


const PostCategory = ({sections}) => {

    const { getCategories, updateCategory, getPosts } = usePostCall();
    const { categories } = useSelector((state) => state.post);

    useEffect(() => {
        getCategories();
    }, [])


  return (
   <div className='blog-tags'>
    <span onClick={() => getPosts()} role="button" className='all-cat d-block text-center text-white mt-1'>ALL</span>
            <ul className="blog-tags d-flex justify-content-evenly">
            {categories?.filter(category => category?.published_post_count > 0).map((category) => {
                    return (
                        <li key={category.id}>
                            <span onClick={() => updateCategory(category?.id)} role="button" className='blog-tag'>
                                <LabelIcon style={{fontSize:"12px"}} /> {category.name} </span>
                                

                        </li>
                    )
                })}

            </ul>
   </div>
  )
}

export default PostCategory