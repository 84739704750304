import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    posts : [],
    categories:[],
    currentCategory:null,
    currentAuthor:null,
    currentPost: null,
    loading:false,
    error:false,
}

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, { payload: { data, url } }) => {
        state[url] = data;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
      fetchEnd: (state) => {
        state.loading = false
      },
  }
});

export const {fetchStart, loadData, fetchFail, fetchEnd} = postSlice.actions

export default postSlice.reducer