import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';

function BankAccountCards({kontaktData}) {
  return (
    <Grid container spacing={2} mt={3}>
      <Grid item xs={4}>
        <Typography variant='h5' textAlign={'center'}>Överföring
</Typography>
        <Card>
          
          <CardContent style={{height:"274px", display:"flex", flexDirection:"column", justifyContent: "center" }}>
            <Typography variant="h6" textAlign={'center'} component="div">
            {kontaktData?.bankgiro_name}
            </Typography>
            <Typography variant='p' textAlign={'center'}  mb={1}>
            {kontaktData?.bankgiro_number}
            </Typography>
            <Typography variant='h6' textAlign={'center'} component="div">
            {kontaktData?.bankkonto_name}
            </Typography>
            <Typography variant='p' textAlign={'center'}>
            {kontaktData?.bankkonto_number}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
      <Typography variant='h6' textAlign={'center'}>Swish {kontaktData?.swish_number}
</Typography>
        <Card>
          <CardMedia
            component="img"
            alt="Swish"
            style={{ height: '100%' , width: '100%' }}
            image={kontaktData?.swihs_image} // Replace with the actual path to your Swish image
          />
        </Card>
      </Grid>
      <Grid item xs={4}>
      <Typography variant='h6' textAlign={'center'}>Bankuppgifter
</Typography>
        <Card>
          
          <CardContent style={{height:"274px", display:"flex", flexDirection:"column" ,justifyContent: "center" }}>
          <Typography variant="h6" textAlign={'center'} component="div">
          {kontaktData?.bank_name}
            </Typography>
            <Typography variant='h6' textAlign={'center'}>
            BIC (SWIFT)
            </Typography>
            <Typography variant='p' textAlign={'center'}>
            {kontaktData?.bank_swift}
            </Typography>
            <Typography variant='h6' textAlign={'center'}>
            IBAN
            </Typography>
            <Typography variant='p' textAlign={'center'}>
            {kontaktData?.bank_iban}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default BankAccountCards;
