import { useEffect, useState } from "react";
import useFooterCall from "../../hooks/useFooterCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FooterModal from "../modals/FooterModal";
import FooterTable from "../tables/FooterTable";

  
const FooterContent = () => {

  const { getFooter } = useFooterCall();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({});


  
  useEffect(() => {
    getFooter();
  }, []);

  return (
    <Box>
      <Typography variant="h4" color="error" mb={4}>
        Footer Content
      </Typography>

      

      <FooterModal
        open={open}
        setOpen={setOpen}
        info={info}
        setInfo={setInfo}
      />

      <FooterTable setOpen={setOpen} setInfo={setInfo} />
    </Box>
  )
}

export default FooterContent
