import { useDispatch } from "react-redux";
import {fetchStart,fetchFail,loadData} from '../features/eventSlice';
import useAxios, { axiosPublic } from "./useAxios";
import {toastSuccessNotify} from '../helper/ToastNotify';

const useEventCall = () => {

    const dispatch = useDispatch();
    const {axiosWithToken} = useAxios();

    const getEvents = async()=>{
        dispatch(fetchStart())
        try {
            const {data} = await axiosPublic.get('api/events/');
            dispatch(loadData(data))
          //console.log(data);
        } catch (error) {
            dispatch(fetchFail())
            console.log(error)
        }
    }

    const addEvent = async(info) => {
      dispatch(fetchStart());
      try {
        const {data} = await axiosWithToken.post('api/events/', info);
        toastSuccessNotify(`${data.eventName} created successfully`)
        getEvents();

      } catch (error) {
        console.log(error);
      }
    }
    const updateEvent = async(id, info) => {
      dispatch(fetchStart());
      try {
        const {data} = await axiosWithToken.put(`api/events/${id}/`, info);
        toastSuccessNotify(`${data.eventName} updated successfully`)
        getEvents();

      } catch (error) {
        console.log(error);
      }
    }

    const deleteEvent = async(id) => {
      dispatch(fetchStart());
      try {
        await axiosWithToken.delete(`api/events/${id}/`);
        toastSuccessNotify(`event with id:${id} deleted successfully`)
        getEvents();

      } catch (error) {
        console.log(error);
      }
    }

  return {
    getEvents,
    addEvent,
    updateEvent,
    deleteEvent
  }
}

export default useEventCall