import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    subscribers : [],
    loading:false,
    error:false,
}

const subscribeSlice = createSlice({
  name: 'subscribe',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, {payload}) => {
        state.subscribers = payload;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
      fetchEnd: (state) => {
        state.loading = false;
      }
  }
});

export const {fetchStart, fetchFail, fetchEnd, loadData} = subscribeSlice.actions

export default subscribeSlice.reducer