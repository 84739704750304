import { createSlice } from '@reduxjs/toolkit'

const initialState = {
members :[],
cities: [],
genders: [],
loading:false,
error:false,

}

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, {payload}) => {
        state.members = payload;
        state.loading = false;
      },
      loadCities: (state, {payload}) => {
        state.cities = payload;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
  }
});

export const {fetchStart, loadData, loadCities, fetchFail} = memberSlice.actions

export default memberSlice.reducer