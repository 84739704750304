import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Select,
  MenuItem,
  Checkbox,
  Paper
} from '@mui/material';
//import {MultiSelect, MultiSelectItem, Select, SelectItem} from '@tremor/react';
import useSortColumn from '../../hooks/useSortColumn';
import UpgradeIcon from "@mui/icons-material/Upgrade";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { arrowStyle, btnHoverStyle, flex } from "../../styles/globalStyle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import useEvenemangCall from "../../hooks/useEvenemangCall";
import { useSelector } from 'react-redux';


const  ParticipantsTable = ({setOpen, setInfo}) => {

  const [selectedEventNames, setSelectedEventNames] = useState([]);
  const {evenemangs} = useSelector((state) => state.evenemang);
  const { deleteParticipant} = useEvenemangCall();


  const columnObj = {
    event_name: 1,
    name: 1,
    id: 1,
  };

  const { sortedData, handleSort, columns } = useSortColumn(
    evenemangs,
    columnObj
    );

  const isEventNamesSelected = (evenemang) =>
    (selectedEventNames?.includes(evenemang.event_name) || selectedEventNames?.length === 0);


    const uniqueEventNames = new Set(evenemangs?.map((item) => item['event_name']));

    //console.log([...uniqueEventNames]);


  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Event's participants Info</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows participants informations"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <div className='flex space-x-2'>
        
        <Select
          multiple
          placeholder='Select City'
          className="w-50"
          value={selectedEventNames}
          onChange={(e) => setSelectedEventNames(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
        >

          {Array.isArray([...uniqueEventNames]) && [...uniqueEventNames]?.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedEventNames?.includes(item)} />
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              <Box sx={arrowStyle} onClick={() => handleSort("id")}>
                <Typography variant="body" noWrap>
                  #
                </Typography>
                {columns.id === 1 && <UpgradeIcon />}
                {columns.id !== 1 && <VerticalAlignBottomIcon />}
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} onClick={() => handleSort("name")}>
                <Typography variant="body" noWrap>
                  Name
                </Typography>
                {columns.name === 1 && <UpgradeIcon />}
                {columns.name !== 1 && <VerticalAlignBottomIcon />}
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} onClick={() => handleSort("event_name")}>
                <Typography variant="body" noWrap>
                  Event Name
                </Typography>
                {columns.event_name === 1 && <UpgradeIcon />}
                {columns.event_name !== 1 && <VerticalAlignBottomIcon />}
              </Box>
              </TableCell><TableCell align="center">
              <Box sx={arrowStyle} onClick={() => handleSort("email")}>
                <Typography variant="body" noWrap>
                  Email
                </Typography>
                {columns.email === 1 && <UpgradeIcon />}
                {columns.email !== 1 && <VerticalAlignBottomIcon />}
              </Box>
              </TableCell>
              <TableCell align="center">Message</TableCell>
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedData) && sortedData?.filter((item) => isEventNamesSelected(item))
              .map((item) => (
                
                <TableRow key={item.id}>
                  <TableCell align='center'>{item.id}</TableCell>
                  <TableCell align='center'>{item.name}</TableCell>
                  <TableCell align="center">{item.event_name}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.message}</TableCell>

                  <TableCell>
                    <Box sx={flex}>
                        <IconButton
                        color='danger'
                        component='span'
                        aria-label="Info"
                        title={`Delete participant data for ${item.name}`}
                        ><DeleteForeverIcon onClick={() => deleteParticipant(item.id)} sx={btnHoverStyle} /></IconButton>
                        
                    </Box>
                  
                </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default ParticipantsTable;