import React, {useState} from 'react';
import  Gallery  from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './CarouselHome.css';
import { useNavigate } from 'react-router-dom';


const Carousel = ({posts}) => {

  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  

  const handleImageClick = () => {
    const post = posts && posts[currentIndex];
    if (post) {
      navigate(`/post/${post.id}/`, { state: post });
    }
  };

  const lastFive = posts?.slice(0,5);
  const images = lastFive?.filter(post => post.is_published === true).map((post) =>({
      original : post.image,
      thumbnail: post.image,
      description: post.title,
    }
  ))



  return (
    <div className="carousel-container">
      <Gallery items={images} slideOnThumbnailOver={true} showNav={false} showPlayButton={false} showFullscreenButton={true} onClick={handleImageClick}  onSlide={(index) => setCurrentIndex(index)} />
    </div>
  );
};

export default Carousel;
