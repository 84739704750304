import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    aboutData: {},
    loading:false,
    error:false,
}

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, {payload}) => {
        state.aboutData = payload;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
  }
});

export const {fetchStart, loadData, fetchFail} = aboutSlice.actions

export default aboutSlice.reducer