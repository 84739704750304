import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    kontaktData :[],
    loading: false,
    error: false,
}

const kontaktSlice = createSlice({
  name: 'kontakt',
  initialState,
  reducers: {fetchStart: (state) => {
    state.loading = true;
    state.error = false;
  },
  loadData: (state, {payload}) => {
    state.kontaktData = payload;
    state.loading = false;
  },
  fetchFail: (state) => {
    state.loading = false;
    state.error = true;
  },}
});

export const {fetchStart, loadData, fetchFail} = kontaktSlice.actions

export default kontaktSlice.reducer