import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    footerData: {},
    loading:false,
    error:false,
}

const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, {payload}) => {
        state.footerData = payload;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
  }
});

export const {fetchStart, loadData, fetchFail} = footerSlice.actions

export default footerSlice.reducer