import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import featureReducer from "../features/featureSlice";
import postReducer from "../features/postSlice";
import footerReducer from "../features/footerSlice";
import partnerReducer from "../features/partnerSlice";
import aboutReducer from "../features/aboutSlice";
import boardReducer from "../features/boardSlice";
import formReducer from "../features/formSlice";
import memberReducer from "../features/memberSlice";
import eventReducer from "../features/eventSlice";
import evenemangReducer from "../features/evenemangSlice";
import kontaktReducer from "../features/kontaktSlice";
import subscribeReducer from "../features/subscribeSlice";
import storage from "redux-persist/lib/storage/"; // defaults to localStorage for web

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    feature:featureReducer,
    post:postReducer,
    footer:footerReducer,
    partner:partnerReducer,
    about:aboutReducer,
    board:boardReducer,
    form:formReducer,
    member:memberReducer,
    event:eventReducer,
    evenemang:evenemangReducer,
    kontakt:kontaktReducer,
    subscribe:subscribeReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
export default store;
