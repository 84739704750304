import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Paper
} from '@mui/material';
import { arrowStyle, btnHoverStyle, flex } from "../../styles/globalStyle";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import { useSelector } from 'react-redux';


const  ContactTable = ({setOpen, setInfo}) => {
  const {kontaktData} = useSelector((state) => state.kontakt);


  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Contact Detail</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows contact detail"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Write to Us
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Write to us Content
                </Typography>
              </Box>
              </TableCell><TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Become Member
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Become member content
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Bank Name
                </Typography>
              </Box>
              </TableCell>
              <TableCell align="center">
              <Box sx={arrowStyle} >
                <Typography variant="body" noWrap>
                  Swish Image
                </Typography>
              </Box>
              </TableCell>
              
              
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
                
                <TableRow key={kontaktData[0]?.id}>
                  <TableCell align='center'>{kontaktData[0]?.write_to_us}</TableCell>
                  <TableCell align='center'>{kontaktData[0]?.wtu_content}</TableCell>
                  <TableCell align="center">{kontaktData[0]?.become_member}</TableCell>
                  <TableCell align="center">{kontaktData[0]?.bm_content}</TableCell>
                  <TableCell align="center">{kontaktData[0]?.bank_name}</TableCell>
                  <TableCell align="center">
                    <img width={120} src={kontaktData[0]?.swihs_image} alt='swish_image' />
                    </TableCell> 
                  
                  <TableCell>
                    <Box sx={flex}>
                    <IconButton
                        color='warning'
                        component='span'
                        aria-label="Info"
                        title={`Update footer data for ${kontaktData[0]?.bank_name}`}
                        >
                          <BorderColorIcon onClick={() => {
                        setOpen(true);
                        setInfo(kontaktData[0] && kontaktData[0]);
                        }} 
                        sx={btnHoverStyle} 
                        />
                        </IconButton>
                    </Box>
                  
                </TableCell>
                </TableRow>
              
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default ContactTable;