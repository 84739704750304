import React, { useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Box,
  Select,
  MenuItem,
  Checkbox,
  Paper
} from '@mui/material';
//import {MultiSelect, MultiSelectItem, Select, SelectItem} from '@tremor/react';
import BadgeDelta from '@mui/material/Badge';
import useSortColumn from '../../hooks/useSortColumn';
import UpgradeIcon from "@mui/icons-material/Upgrade";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { arrowStyle, btnHoverStyle, flex } from "../../styles/globalStyle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import useMemberCall from "../../hooks/useMemberCall";
import { useSelector } from 'react-redux';


const  MembersTable = ({setOpen, setInfo}) => {
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedCities, setSelectedCities] = useState([]);
  const {members} = useSelector((state) => state.member);
  const { deleteMember, send_activation_mail} = useMemberCall();


  const columnObj = {
    city: 1,
    age: 1,
    gender: 1,
    member_status: 1,
    id: 1,
  };

  const { sortedData, handleSort, columns } = useSortColumn(
    members,
    columnObj
    );

  const isMemberCitySelected = (member) =>
    (`${member.member_status}` === selectedStatus || selectedStatus === 'all') &&
    (selectedCities?.includes(member.city) || selectedCities?.length === 0);


  // const uniqueMemberCities = new Set(Array.isArray(members) && members?.map((item) => item['city']));

  const uniqueMemberCities = new Set(Array.isArray(members) ? members?.map((item) => item['city'])  : [])

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Members Info</Typography>
        <IconButton
          color="primary"
          aria-label="Info"
          component="span"
          title="Shows members information"
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <div className='flex space-x-2'>
        <Select
        className='w-50'
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="true">Active</MenuItem>
          <MenuItem value="false">Passive</MenuItem>
        </Select>
        <Select
          multiple
          placeholder='Select City'
          className="w-50"
          value={selectedCities}
          onChange={(e) => setSelectedCities(e.target.value)}
          renderValue={(selected) => selected.join(', ')}
        >

          {Array.isArray([...uniqueMemberCities]) && [...uniqueMemberCities]?.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={selectedCities?.includes(item)} />
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>
      <TableContainer component={Paper} sx={{ mt: 3 }} elevation={10}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              <Box sx={arrowStyle} onClick={() => handleSort("id")}>
                <Typography variant="body" noWrap>
                  #
                </Typography>
                {columns.id === 1 && <UpgradeIcon />}
                {columns.id !== 1 && <VerticalAlignBottomIcon />}
              </Box>
              </TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Surname</TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("age")}>
                <Typography variant="body" noWrap>
                  Age
                </Typography>
                {columns.age === 1 && <UpgradeIcon />}
                {columns.age !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("gender")}>
                <Typography variant="body" noWrap>
                  Gender
                </Typography>
                {columns.age === 1 && <UpgradeIcon />}
                {columns.age !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("city")}>
                <Typography variant="body" noWrap>
                  City
                </Typography>
                {columns.city === 1 && <UpgradeIcon />}
                {columns.city !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Phone</TableCell>
              <TableCell align="center"><Box sx={arrowStyle} onClick={() => handleSort("member_status")}>
                <Typography variant="body" noWrap>
                Status
                </Typography>
                {columns.stock === 1 && <UpgradeIcon />}
                {columns.stock !== 1 && <VerticalAlignBottomIcon />}
              </Box></TableCell>
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedData) && sortedData?.filter((item) => isMemberCitySelected(item))
              .map((item) => (
                
                <TableRow key={item.id}>
                  <TableCell align='center'>{item.id}</TableCell>
                  <TableCell align='center'>{item.first_name}</TableCell>
                  <TableCell align="center">{item.last_name}</TableCell>
                  <TableCell align="center">{item.age}</TableCell>
                  <TableCell align="center">{item.gender}</TableCell>
                  <TableCell align="center">{item.city}</TableCell>
                  <TableCell align="center">{item.email}</TableCell>
                  <TableCell align="center">{item.phone_number}</TableCell>
                  
                  <TableCell align="center">
                    <BadgeDelta
                      badgeContent={`${item.member_status === true ? "Active" : "Passive"}`}
                      color={`${item.member_status === true ? "success" : "error"}`}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={flex}>
                    <IconButton
                        color='warning'
                        component='span'
                        aria-label="Info"
                        title={`Update member data for ${item.first_name} ${item.last_name}`}
                        >
                          <BorderColorIcon onClick={() => {
                        setOpen(true);
                        setInfo(item);
                        }} 
                        sx={btnHoverStyle} 
                        />
                        </IconButton>
                        <IconButton
                        color='danger'
                        component='span'
                        aria-label="Info"
                        title={`Delete member data for ${item.first_name} ${item.last_name}`}
                        ><DeleteForeverIcon onClick={() => deleteMember(item.id)} sx={btnHoverStyle} /></IconButton>
                        
                        <IconButton
                        color='info'
                        component='span'
                        aria-label="Info"
                        title={`Send confirmation mail to ${item.first_name}`}
                        >
                        <ForwardToInboxIcon onClick={() => send_activation_mail(item.id)}/>
                        </IconButton>
                    </Box>
                  
                </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


export default MembersTable;