import React, { useEffect, useState } from "react";
import useAboutCall from "../../hooks/useAboutCall";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AboutModal from "../modals/AboutModal";
import AboutTable from "../tables/AboutTable";
const AboutContent = () => {
    const { getAbout } = useAboutCall();
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState({});
  
  
    
    useEffect(() => {
      getAbout();
    }, []);
  
    return (
      <Box>
        <Typography variant="h4" color="error" mb={4}>
          About Content
        </Typography>
  
        
  
        <AboutModal
          open={open}
          setOpen={setOpen}
          info={info}
          setInfo={setInfo}
        />
  
        <AboutTable setOpen={setOpen} setInfo={setInfo} />
      </Box>
    )
}

export default AboutContent



