// TermsAndConditionsModal.js
import React, {useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useAboutCall from '../../hooks/useAboutCall';
import {useSelector} from 'react-redux';

const TermsAndConditionsModal = ({open, setOpen}) => {

  const {getAbout} = useAboutCall();
  const {aboutData} = useSelector((state) => state.about);

  useEffect(() => {
    getAbout();
  }, [])
  

  

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Terms and Conditions
        <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: '8px',
              top: '8px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Your terms and conditions content goes here */}
          <p>{aboutData[0]?.statute}</p>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TermsAndConditionsModal;
